import 'aframe-mouse-cursor-component'
require('@/plugins/physics/dist/aframe-physics-system');
window['CANNON'].Ray.prototype._updateDirection = window['CANNON'].Ray.prototype.updateDirection

const textures = {}


let questionMaterial = null;

const Components = {
    lightmaps: {},
  
    configureTexture(options){
        textures[options.id] = options
    },
    addLightmap(id, details){
      this.lightmaps[id] = details
    },
    applyTexture(entity, params, repeatXY, lightmap = null){
      const texture = params.id
      const options = textures[texture]

      // see if a custom lightmap exists for this texture
      if (!this.lightmaps[lightmap])
        lightmap = null

      if (!options)
        return

      let repeat = `${repeatXY.x} ${repeatXY.y}`
      if (params.repeat){
        repeat = `${repeatXY.x * params.repeat} ${repeatXY.y * params.repeat}`
      }
      let color = params.color || "#FFF"
      color = color.substr(0, 7)

      entity.setAttribute("src",  `#${texture}`)

      if (options.maps.includes("ao")  && !lightmap){
        entity.setAttribute("ambient-occlusion-map", `#${texture}-ambient`)
        entity.setAttribute("ambient-occlusion-texture-repeat", repeat)
      }else if (lightmap) {
        if (this.lightmaps[lightmap].ao) {
          entity.setAttribute("ambient-occlusion-map", `#${lightmap}AO`)
        }
        entity.setAttribute("ambient-occlusion-texture-repeat", params.lightRepeat || "1 1")
        entity.setAttribute("ambient-occlusion-texture-offset", params.lightOffset || "0 0")
        entity.setAttribute("ambient-occlusion-map-intensity", this.lightmaps[lightmap].intensity || 0.8)
      }
      if (options.maps.includes("normal")){
        entity.setAttribute("normal-map", `#${texture}-normal`)
        entity.setAttribute("normal-texture-repeat", repeat)
      }
      entity.setAttribute("repeat", repeat)
      entity.setAttribute("color",  color)
      if (params.normalScale){
          entity.setAttribute("normal-scale", `${params.normalScale} ${params.normalScale}`)
      }
      if (options.maps.includes("rough")){
        entity.setAttribute("roughness", 1)
        entity.setAttribute("roughness-map",  `#${texture}-rough`)
        entity.setAttribute("roughness-texture-repeat", repeat)
      } else {
        entity.setAttribute("roughness", 0.5)
      }

 //     if (options.maps.includes("height")){
 //       entity.setAttribute("displacement-map",  `#${texture}-height`)
 //       entity.setAttribute("displacement-texture-repeat", repeat)
 //     } 

      if (options.maps.includes("emissive")){
        params.emissive = params.emissive || {color: "#FFF", intensity: 1}
        entity.setAttribute("emissive",  params.emissive.color)
        entity.setAttribute("emissive-map",  `src: #${texture}-emissive`)
        entity.setAttribute("emissive-intensity",  params.emissive.intensity)
      }

      if (options.maps.includes("alpha")){
        entity.setAttribute("alpha", 1)
        entity.setAttribute("alpha-map",  `src: #${texture}-alpha`)
      }
      if (options.maps.includes("metal")){
         entity.setAttribute("metalness", 1)
         entity.setAttribute("metalness-map",  `#${texture}-metal`)
        entity.setAttribute("metalness-texture-repeat", repeat)
      } else {
        entity.setAttribute("metalness", 0)
      }
      if (lightmap && this.lightmaps[lightmap].light){
          entity.setAttribute("light-map", `src: #${lightmap}L` )
      }
    },

    setup(){
      questionMaterial =  new window.THREE.MeshBasicMaterial({
        map: new window.THREE.TextureLoader().load( "/question.jpg" )
      })

      if (!window.AFRAME.components.skirting){
        window.AFRAME.registerComponent('skirting', {
            schema: {
              color: {
                default: "#DDD",
                type: 'string'
              },
            },
            init: function() {

              const width = this.el.components.geometry.data.width
              const height = this.el.components.geometry.data.height
              const depth = this.el.components.geometry.data.depth

              const mat = this.el.components.material.material 
//              console.log("-", this.el.components)

              if (this.el.components.geometry.data.primitive == "box"){
                let geometry = new window.THREE.BoxGeometry(width+0.18, 0.25, depth+0.15)
                let material = new window.THREE.MeshStandardMaterial({
                  color: this.data.color,         
                })
                let mesh = new window.THREE.Mesh(geometry, material)
                mesh.position.set(0, (-height/2) +0.125 , 0 )
  
                this.el.object3D.add(mesh)                
  
              } else {
                let geometry = new window.THREE.BoxGeometry(width+0.18, 0.25, 0.1)
                let material = new window.THREE.MeshStandardMaterial({
                  color: this.data.color
                })
                
//                if (mat.aoMap){
                  material.aoMap = mat.aoMap;
                  material.aoMapIntensity = mat.aoMapIntensity;
      //            console.log("--", material)


  //              }
                let mesh = new window.THREE.Mesh(geometry, material)
                mesh.position.set(0, (-height/2) +0.125 , 0.05 )

                this.el.object3D.add(mesh)                
              }
            }
          });
      }
      if (!window.AFRAME.components.moulding){
        window.AFRAME.registerComponent('moulding', {
            schema: {
              color: {
                default: "#DDD",
                type: 'string'
              },
            },
            init: function() {
              const width = this.el.components.geometry.data.width
              const height = this.el.components.geometry.data.height
              let geometry = new window.THREE.BoxGeometry(width, 0.25, 0.1)
              let material = new window.THREE.MeshStandardMaterial({
                color: this.data.color
              })
              let mesh = new window.THREE.Mesh(geometry, material)
              mesh.position.set(0, (height/2)-0.125, 0.05 )
              this.el.object3D.add(mesh)        
              mesh = new window.THREE.Mesh(geometry, material)
              mesh.position.set(0, (height/2)-0.06,0.1 )
              this.el.object3D.add(mesh)                     

            }
          });
        }
        if (!window.AFRAME.components.artframe){
            window.AFRAME.registerComponent('artframe', {
                schema: {
                  frame: {
                    default: false,
                    type: 'boolean'
                  },
                  question: {
                    default: false,
                    type: 'boolean'
                  },
                  artId: {
                    type: 'string'
                  },
                  frameId: {
                    type: 'string'
                  }
                },
                init: function() {
                  this.el.components.material.material.colorWrite = false

                  const depth = this.data.frame ? 0.14 : 0.04

                  const width = this.el.components.geometry.data.width
                  const height = this.el.components.geometry.data.height

                  let material = null;
                  if (this.data.question){
                    material = questionMaterial
                  } else {
                    material = new window.THREE.MeshBasicMaterial({
                      map: new window.THREE.TextureLoader().load( this.el.attributes.imagesrc.value )
                    })
                  }
                  let geometry = new window.THREE.PlaneGeometry(width, height)
                  let mesh = new window.THREE.Mesh(geometry, material)
                  mesh.position.set(0, 0, depth)

                  this.el.object3D.add(mesh)

                  let geometry1 = new window.THREE.PlaneGeometry(depth, height)
                  let material1 = new window.THREE.MeshBasicMaterial({
                    color:'#444'
                  })
    
                  let mesh1 = new window.THREE.Mesh(geometry1, material1)
                  mesh1.rotation.set(0, -Math.PI/2, 0)
                  mesh1.position.set(-width/2, 0, depth/2)
                  this.el.object3D.add(mesh1)
    
                  let geometry2 = new window.THREE.PlaneGeometry(depth, height)
                  let mesh2 = new window.THREE.Mesh(geometry2, material1)
                  mesh2.rotation.set(0, Math.PI/2, 0)
                  mesh2.position.set(width/2, 0, depth/2)
                  this.el.object3D.add(mesh2)
    
                  let geometry3 = new window.THREE.PlaneGeometry(width, depth)
                  let mesh3 = new window.THREE.Mesh(geometry3, material1)
                  mesh3.rotation.set(-Math.PI/2, 0, 0)
                  mesh3.position.set(0, height/2, depth/2)
                  this.el.object3D.add(mesh3)
    
                  let geometry4 = new window.THREE.PlaneGeometry(width, depth)
                  let mesh4 = new window.THREE.Mesh(geometry4, material1)
                  mesh4.rotation.set(Math.PI/2, 0, 0)
                  mesh4.position.set(0, -height/2, depth/2)
                  this.el.object3D.add(mesh4)
    
                  if (this.data.frame){
                    let material2 = new window.THREE.MeshBasicMaterial({
                      color:'#DDD'
                    })
    
                    let geometry5 = new window.THREE.BoxGeometry(width+ 0.6, height+0.6, 0.1)
                    let mesh5 = new window.THREE.Mesh(geometry5, material2)
                    this.el.object3D.add(mesh5)
    
                    let material3 = new window.THREE.MeshBasicMaterial({
                      color:'#222'
                    })
    
                    let geometry6 = new window.THREE.BoxGeometry(width + 0.7, 0.05, 0.1)
                    let mesh6 = new window.THREE.Mesh(geometry6, material3)
                    mesh6.position.set(0, -(height + 0.65)/2, 0.0)
                    this.el.object3D.add(mesh6)
    
                    let geometry7 = new window.THREE.BoxGeometry(width + 0.7, 0.05, 0.1)
                    let mesh7 = new window.THREE.Mesh(geometry7, material3)
                    mesh7.position.set(0, (height + 0.65)/2, 0.0)
                    this.el.object3D.add(mesh7)
    
                    let geometry8 = new window.THREE.BoxGeometry(0.05, height + 0.7, 0.1)
                    let mesh8 = new window.THREE.Mesh(geometry8, material3)
                    mesh8.position.set(-(width + 0.65)/2, -0, 0.0)
                    this.el.object3D.add(mesh8)
    
                    let geometry9 = new window.THREE.BoxGeometry(0.05, height +0.7,  0.1)
                    let mesh9 = new window.THREE.Mesh(geometry9, material3)
                    mesh9.position.set((width + 0.65)/2, 0, 0.0)
                    this.el.object3D.add(mesh9)
                  }
    
              }
            })
          }
    
          if (!window.AFRAME.components.occlude){
            window.AFRAME.registerComponent('occlude', {
                init: function() {
                  var geometry = this.el.object3DMap.mesh.geometry;
                  geometry.setAttribute( 'uv2', new window.THREE.BufferAttribute( geometry.attributes.uv.array, 2 ) );
              }
            })
          }

          if (!window.AFRAME.components['alpha-map']){
            window.AFRAME.registerComponent('alpha-map', {
              schema: {src: {type: 'map'}},
              init: function() {
                const data = {
                  "alphaMap": this.data.src,
                  "alphaTextureRepeat": this.el.components.material.data.repeat
                }
                window.AFRAME.utils.material.updateDistortionMap('alpha', this.el.components.material, data);
              }              
            });
          }
          if (!window.AFRAME.components['emissive-map']){
            window.AFRAME.registerComponent('emissive-map', {
              schema: {src: {type: 'map'}},
              init: function() {
                const data = {
                  "emissiveMap": this.data.src,
                  "emissiveTextureRepeat": this.el.components.material.data.repeat
                }
                window.AFRAME.utils.material.updateDistortionMap('emissive', this.el.components.material, data);
              }              
            });
          }    
          if (!window.AFRAME.components['light-map']){
            window.AFRAME.registerComponent('light-map', {
              schema: {
                src: {type: 'map'},
                repeat: {type: 'vec2', default: {x: 1, y: 1}},
                wrap: {type: 'vec2', default: {x: 1, y: 1}},
                offset: {type: 'vec2', default: {x: 1, y: 1}}
              },
              init: function() {
                const data = {
                  "lightMap": this.data.src,
                  "lightTextureRepeat": this.data.repeat,
                  "lightTextureOffset": this.data.offset,
                  "lightTextureWrap": this.data.wrap,
                }
                window.AFRAME.utils.material.updateDistortionMap('light', this.el.components.material, data);
              }              
            });
          }    
      },
    
}

export default Components