import 'aframe-mouse-cursor-component'
require('@/plugins/physics/dist/aframe-physics-system');
window['CANNON'].Ray.prototype._updateDirection = window['CANNON'].Ray.prototype.updateDirection

import Components from '@/components/aframe/components'

const Gallery = {
 
    addArt(options){
        const scene = options.scene

        const entity = document.createElement("a-box");
        entity.setAttribute("position", `${options.x} ${options.height/2 + (options.heightOffset || 0)} ${-options.y}`)
        entity.setAttribute("rotation", options.rotation)
        if (options.scale)
          entity.setAttribute("scale", `${options.scale} ${options.scale}`)
        entity.setAttribute("width", 3*options.imgWidth)
        entity.setAttribute("height", 3*options.imgHeight)
        entity.setAttribute("material", "transparent: true;");
        entity.setAttribute("artframe", `frame: ${options.frame}; artId: ${options.artId}; frameId: ${options.id}; question: ${options.question}`)
        entity.setAttribute("imagesrc", options.src)
        entity.setAttribute("class", "art")

        scene.appendChild(entity)

        if (options.light){
          const lightDest = document.createElement("a-entity");
          lightDest.setAttribute("position", `${options.x} ${(options.z || 0) + options.height} -${options.y}`)
          lightDest.id = `artlight${options.artId}`
          scene.appendChild(lightDest)
    
          const light = document.createElement("a-entity");
          light.setAttribute("position", `${options.x - options.viewOffset.x} ${(options.z || 0)} ${-options.y+options.viewOffset.y}`)
          light.setAttribute("light", `type: spot; angle: 10; color: #FFF; intensity: 0.8; castShadow: false;penumbra	:0.5; target:#artlight${options.artId}`)
          scene.appendChild(light)
        }

        if (options.lightOffset){
          const cone = document.createElement("a-cone");
  //        console.log(options.roomHeight, options, (options.z || 0) + options.height-3.5)
          cone.setAttribute("radius-top", `0.1`)
          cone.setAttribute("radius-bottom", `0.2`)
          cone.setAttribute("height", 0.2)
          cone.setAttribute("color", "#FFF")
          cone.setAttribute("open-ended", false)

          const box = document.createElement("a-box");
  //        console.log(options.roomHeight, options, (options.z || 0) + options.height-3.5)
          box.setAttribute("position", `${options.x + options.viewOffset.x/3} ${(options.room.height - 0.25)} ${-options.y-options.viewOffset.y/3}`)
          box.setAttribute("width", 0.1)
          box.setAttribute("depth", 0.1)
          box.setAttribute("height", 0.3)
          box.setAttribute("color", "#CCC")


          if (options.direction == "N"){
            cone.setAttribute("rotation", "45 0 0")
            cone.setAttribute("position", `${options.x + options.viewOffset.x/3} ${(options.room.height - 0.4)} ${-options.y-options.viewOffset.y/3 - 0.07}`)
          } else if (options.direction == "S"){
            cone.setAttribute("rotation", "-45 0 0")
            cone.setAttribute("position", `${options.x + options.viewOffset.x/3} ${(options.room.height - 0.4)} ${-options.y-options.viewOffset.y/3 + 0.07}`)
          } else if (options.direction == "E"){
            cone.setAttribute("rotation", "0 0 45")
            cone.setAttribute("position", `${options.x + options.viewOffset.x/3 + 0.07} ${(options.room.height - 0.4)} ${-options.y-options.viewOffset.y/3}`)
          } else if (options.direction == "W"){
            cone.setAttribute("rotation", "0 0 -45")
            cone.setAttribute("position", `${options.x + options.viewOffset.x/3 + 0.07} ${(options.room.height - 0.4)} ${-options.y-options.viewOffset.y/3}`)
          }

          scene.appendChild(cone)        
          scene.appendChild(box)    
          let lightX = 2048/(options.room.width/(options.lightOffset-3))
          let lightWidth = 12000/options.room.width
          if (options.direction == "E" || options.direction == "W"){
            lightX = 2048/(options.room.depth/(options.lightOffset-3))
            lightWidth = 12000/options.room.depth
          }

          Components.lightmaps[`${options.room.id}${this.flipEW(options.direction)}`].light.push({id: "wallLight", x: lightX, y: 200, height: 800, width: lightWidth})
        }

        return entity
      },
      addRoom(options){


        const scene = options.scene
        // delete if laready loaded
        if (scene.querySelector("#" + options.id))
          scene.querySelector("#" + options.id).remove()

        const entity = document.createElement("a-entity");
        entity.id = options.id

        let extraHeight = 0;
        let endLights = true;

        options.walls  = options.walls || {}
        options.roof  = options.roof || {}
        options.floor  = options.floor || {}

        let wallLights = (options.walls.lights ? "3" : false)

        entity.innerHTML = ``

        if (options.roof.type == "apex"){
          extraHeight = 6;
          endLights = false;
          options.roof.angle  = options.roof.angle || 60
          options.roof.beams = options.roof.beams || {type:"cross"}
          this.createApexRoof( entity, options)
        } else if (options.roof.type == "warehouse"){
            extraHeight = 6;
            endLights = false;
            options.roof.angle  = options.roof.angle || 75
            options.roof.beams = options.roof.beams || {type:"ceiling"}
            this.createApexRoof( entity, options)
        } else {
          this.createSkylightCeiling( entity, options)
        }

        
        if (options.walls.windows){
          entity.appendChild(this.createWindows(options))
        }

        entity.appendChild(this.createWall({
          x: options.x, 
          y: options.y + (options.depth/2), 
          z: options.z,
          rotation: "0 0 0",
          width: options.width, 
          wallLights: endLights ?  wallLights : false,
          moulding: (options.walls.moulding && endLights),
          skirting: options.walls.skirting,
          height: options.height + extraHeight,
          texture: options.walls.textureNS || options.walls.texture,
          beams: options.walls.NSbeams,
          fullOptions: options,
          compass: "N",
          roomId: options.id
        }
        ))
        entity.appendChild(this.createWall({
          x: options.x - (options.width/2), 
          y: options.y, 
          z: options.z,
          rotation: "0 90 0",
          width: options.depth, 
          height: options.height,
          wallLights: wallLights,
          moulding: options.walls.moulding,
          skirting: options.walls.skirting,
          doorway: options.doorways.west,
          texture: options.walls.texture,
          beams: options.walls.EWbeams,
          fullOptions: options,
          compass: "E",
          roomId: options.id
          }
        ))
        entity.appendChild(this.createWall({
          x: options.x, 
          y: options.y - (options.depth/2), 
          z: options.z,
          rotation: "0 180 0",
          width: options.width, 
          wallLights:  endLights ? wallLights : 0,
          moulding: (options.walls.moulding && endLights),
          skirting: options.walls.skirting,
          height: options.height + extraHeight,
          texture: options.walls.textureNS || options.walls.texture,
          beams: options.walls.NSbeams,
          fullOptions: options,
          compass: "S",
          roomId: options.id
        }
        ))        
        entity.appendChild(this.createWall({
          x: options.x + (options.width/2), 
          y: options.y, 
          z: options.z,
          rotation: "0 -90 0",
          width: options.depth, 
          height: options.height,
          wallLights: wallLights,
          moulding: options.walls.moulding,
          skirting: options.walls.skirting,
          doorway: options.doorways.east,
          texture: options.walls.texture,
          beams: options.walls.EWbeams,
          fullOptions: options,
          compass: "W",
          roomId: options.id
        }
        ))
        entity.appendChild(this.createFloor({
          x: options.x, 
          y: options.y, 
          z:options.z,
          width: options.width, 
          depth: options.depth,
          texture: options.floor.texture,
          roomId: options.id
        }))

        if (options.centre && options.centre.type == "bench")
          entity.appendChild(this.createBench(options))
        else if (options.centre && options.centre.type == "pedistool")
          entity.appendChild(this.createPedistool(options))


        if (options.displayBoards){
          entity.appendChild(this.createDisplayBoards(options))
        }


        /*
        const lightDest = document.createElement("a-entity");
        lightDest.setAttribute("position", `0 4 -20`)
        lightDest.id = `artlight`
        entity.appendChild(lightDest)
  
        const light = document.createElement("a-entity");
        light.setAttribute("position", `0 10 -15`)
        light.setAttribute("light", `type: spot; angle: 20; color: #FFF; intensity: 0.7; castShadow: false;penumbra	:0.5; target:#artlight`)
        entity.appendChild(light)
        */

        scene.appendChild(entity)
      },
      createApexRoof(entity, options){

        const angle = options.roof.angle
        const oppAngle = 90 - angle

        const s60 = Math.sin(Math.PI/(180/angle))
        const t60 = Math.tan(Math.PI/(180/angle))

        if (options.roof.roofLights == "4" || options.roof.roofLights == "8" || options.roof.roofLights == "12"){
          entity.appendChild(this.creatRoofLantern(
            {
              x: options.x + (options.width/4), 
              y: options.y + (options.depth/4), 
              z:options.z,
              height: options.height+ (options.width/2)/(t60*2),
              rotation: `0 0 -${oppAngle}`,
              depth:0.2
            }
          )) 
          entity.appendChild(this.creatRoofLantern(
            {
              x: options.x + (options.width/4), 
              y: options.y - (options.depth/4), 
              z:options.z,
              height: options.height+ (options.width/2)/(t60*2),
              rotation: `0 0 -${oppAngle}`,
              depth:0.2
            }
          )) 
          entity.appendChild(this.creatRoofLantern(
            {
              x: options.x - (options.width/4), 
              y: options.y + (options.depth/4), 
              z:options.z,
              height: options.height+ (options.width/2)/(t60*2),
              rotation: `0 0 ${oppAngle}`,
              depth:0.2
            }
          ))         
          entity.appendChild(this.creatRoofLantern(
            {
              x: options.x - (options.width/4), 
              y: options.y - (options.depth/4), 
              z:options.z,
              height: options.height+ (options.width/2)/(t60*2),
              rotation: `0 0 ${oppAngle}`,
              depth:0.2
            }
          ))  
        } else if (options.roof.roofLights == "pendant"){
          for (let i = 1; i <= (options.depth / 5); i+=2){
            entity.appendChild(this.creatRoofPendant(
              {
                x: options.x - (options.width/4), 
                y: options.y  + (options.depth/2) -  i*5 + 2.5 , 
                z: options.z,
                height: options.height+ (options.width/2)/(t60*2)
              }
            )) 
            entity.appendChild(this.creatRoofPendant(
              {
                x: options.x + (options.width/4), 
                y:  options.y + (options.depth/2) -  i*5 + 2.5 ,  
                z: options.z,
                height: options.height + (options.width/2)/(t60*2)
              }
            )) 
          }
         }
         entity.appendChild(this.createCeiling(
          {
            x: options.x - options.width/4, 
            y: options.y, 
            z:options.z, 
            width: (options.width/2)/s60,
            depth: options.depth,
            height: options.height+ (options.width/2)/(t60*2),
            rotation: `${angle} 90 90`,
            texture: options.roof.texture
            }
        ))
        entity.appendChild(this.createCeiling(
          {
            x: options.x + options.width/4, 
            y: options.y, 
            z:options.z, 
            width: (options.width/2)/s60,
            depth: options.depth,
            height: options.height+(options.width/2)/(t60*2),
            rotation: `${angle} -90 90`,
            texture: options.roof.texture
            }
        ))
        
        if (options.roof.beams.type == "cross"){
          const count = options.depth/5
          for (let i = 1; i < count; i++){
            entity.appendChild(this.createBeam(
              {
                x: options.x,  
                y: options.y - options.depth/2 + i*5, 
                z: (options.z|| 0)+options.height-0.2, 
                width: options.width,
                depth: 0.2,
                height: 0.2,
                texture:  options.roof.textureBeam || options.roof.texture
              }
            ))
          }
        } else if (options.roof.beams.type == "ceiling"){
          const count = options.depth/5
          for (let i = 0; i <= count; i++){
            entity.appendChild(this.createBeam(
              {
                x: options.x - options.width/4,  
                y: options.y - options.depth/2 + i*5, 
                z: (options.z|| 0)+options.height-0.2  + (options.width/2)/(t60*2), 
                width: options.width/2,
                depth: 0.2,
                height: 0.2,
                rotation: `0 0 ${oppAngle}`,
                texture: options.roof.textureBeam || options.roof.texture
              }
            ))
            entity.appendChild(this.createBeam(
              {
                x: options.x + options.width/4,  
                y: options.y - options.depth/2 + i*5, 
                z: (options.z|| 0)+options.height-0.2  + (options.width/2)/(t60*2), 
                width: options.width/2,
                depth: 0.2,
                height: 0.2,
                rotation: `0 0 -${oppAngle}`,
                texture: options.roof.textureBeam || options.roof.texture
              }
            ))            
          }
        }
        

        entity.appendChild(this.createBeam(
          {
            x: options.x,  
            y: options.y, 
            z: (options.z|| 0)+options.height+(options.width/2)/(t60)-0.5, 
            width: 0.5,
            depth: options.depth,
            height: 0.5,
            texture: options.roof.textureBeam || options.roof.texture
          }
        ))    
      },    
      createSkylightCeiling( entity, options){
        if (options.roof.roofLights){
          let lights = [] 
          if (options.roof.roofLights == "strip"){
            lights.push({x: -(options.width/2)+4.2, y: 0, z: 0, width: 1, length: options.depth, noSpot: true })
            lights.push({x: -(options.width/2)+11.8, y: 0, z: 0, width: 1, length: options.depth, noSpot: true })
          }
          if (options.roof.roofLights == "4"){
            lights.push({x: (options.width/4), y: (options.depth/4), z: 0 })
            lights.push({x: -(options.width/4), y: (options.depth/4), z: 0 })
            lights.push({x: -(options.width/4), y: -(options.depth/4), z: 0 })
            lights.push({x: (options.width/4), y: -(options.depth/4), z: 0 })
          }
          if (options.roof.roofLights == "8"){
            const x = 1.8;
            const y = 1.8;
            lights.push({x: (x), y: (y), z: 0 })
            lights.push({x: -(x), y: (y), z: 0 })
            lights.push({x: -(x), y: -(y), z: 0 })
            lights.push({x: (x), y: -(y), z: 0 })
            lights.push({x: (x), y: -(y+3.5), z: 0 })
            lights.push({x: -(x), y: -(y+3.5), z: 0 })
            lights.push({x: (x), y: +(y+3.5), z: 0 })
            lights.push({x: -(x), y: +(y+3.5), z: 0 })
          }
          if (options.roof.roofLights == "12"){
//            const x = 1.8;
            const y = 1.8;
            lights.push({x: 0, y: (y), z: 0 })
            lights.push({x: -(3.5), y: (y), z: 0 })
            lights.push({x: +(3.5), y: (y), z: 0 })
            lights.push({x: 0, y: -(y), z: 0 })
            lights.push({x: (+3.5), y: -(y), z: 0 })
            lights.push({x: (-3.5), y: -(y), z: 0 })
            lights.push({x: 0, y: -(y+3.5), z: 0 })
            lights.push({x: -3.5, y: -(y+3.5), z: 0 })
            lights.push({x: +3.5, y: -(y+3.5), z: 0 })
            lights.push({x: 0, y: +(y+3.5), z: 0 })
            lights.push({x: -3.5, y: +(y+3.5), z: 0 })
            lights.push({x: +3.5, y: +(y+3.5), z: 0 })
          }
          lights.forEach ( (light) => {
            entity.appendChild(this.creatRoofLantern(
              {
                x:  options.x + light.x, 
                y: options.y + light.y, 
                z: options.z + light.z,
                height: options.height,
                width: light.width,
                length: light.length,
                noSpot: light.noSpot
              }
            ))
          })
        }
        entity.appendChild(this.createCeiling(
          {
            x: options.x, 
            y: options.y, 
            z:options.z, 
            width: options.width,
            depth: options.depth,
            height: options.height,
            texture: options.roof.texture
            }
        ))
      },

      createCorridor(options){

     //   const w1 = (options.width - options.doorway.width)/2 - options.doorway.position;
   //     const p1 = -options.width/2 + (w1/2)

        const pos = options.y + options.width/2 - 5

        const entity = document.createElement("a-entity");
        entity.appendChild(this.createWall({
          x: options.x + 2.5, 
          y: pos + 2, 
          z: options.z,
          rotation: "0 0 0",
          width: 5, 
          height: 4,
          texture: options.texture,
          skirting: options.skirting,
          moulding: false,
          roomId: "corridor"
        }
        )) 
        entity.appendChild(this.createWall({
          x:  options.x + 2.5, 
          y:  pos - 2, 
          z: options.z,
          rotation: "0 180 0",
          width: 5, 
          height: 4,
          texture: options.texture,
          skirting: options.skirting,
          moulding: false,
          roomId: "corridor"
        }
        ))     
        entity.appendChild(this.createCeiling({
          x: options.x + 2.5, 
          y: pos, 
          z: options.z,
          width: 5, 
          depth: 4,
          height: 4,
        }
        ))      
        entity.appendChild(this.createFloor({
          x: options.x + 2.5, 
          y: pos, 
          z: options.z,
          width: 5, 
          depth: 4,
          texture: options.fullOptions.floor.texture,
          roomId: "corridor"
        }
        ))          
        return entity;
      },
      createWall(options){

        if (options.doorway){
          const entity = document.createElement("a-entity");
          if (options.doorway.corridor)
            entity.appendChild(this.createCorridor(options));

          // a doorway means it's we need 3 walls
          const w1 = (options.width - options.doorway.width)/2 - options.doorway.position;
          const p1 = -options.width/2 + (w1/2)

          const w1LightOffset = options.compass == "W" ? 1 - w1/options.width : 0

          // main wall
          entity.appendChild(this.createWall({
            x: options.x, 
            y: options.y + p1, 
            z: options.z,
            rotation: options.rotation,
            width: w1, 
            wallLights: (options.wallLights ? "2": false ),
            height: options.height,
            texture: options.texture,
            skirting: options.skirting,
            moulding: options.moulding,
            beams: options.beams,
            fullOptions: options.fullOptions,
            compass: options.compass,
            roomId: options.roomId,              
            lightRepeat: `${w1/options.width} 1`,
            lightOffset: `${w1LightOffset} 0`
          }
          )) 

          const w2 = (options.width - options.doorway.width)/2 + options.doorway.position;
          const w2LightOffset = options.compass == "W" ? 0 : 1 - w2/options.width

          const p2 = -options.width/2 + (w2/2)
          // small wall
           entity.appendChild(this.createWall({
              x: options.x, 
              y: options.y - p2, 
              z: options.z,
              rotation: options.rotation,
              wallLights:  (options.wallLights ? "0" : false ),
              width: w2, 
              height: options.height,
              texture: options.texture,
              skirting: options.skirting,
              fullOptions: options.fullOptions,
              moulding: options.moulding,
              compass: options.compass,
              roomId: options.roomId,
              lightRepeat: `${w2/options.width} 1`,
              lightOffset: `${w2LightOffset} 0`
            }
            ))  


          const p3 = - options.doorway.position;
          const w3LightOffset = options.compass == "W" ? w2/options.width : w1/options.width

          // top wall above door
           entity.appendChild(this.createWall({
              x: options.x, 
              y: options.y + p3, 
              z: (options.z||0) + options.doorway.height,
              rotation: options.rotation,
              wallLights:  (options.wallLights ? "0" : false ),
              width: options.doorway.width, 
              height: options.height - options.doorway.height,
              texture: options.texture,
              skirting: false,
              fullOptions: options.fullOptions,
              moulding: options.moulding,
              compass: options.compass,
              roomId: options.roomId,               
              lightRepeat: `${options.doorway.width/options.width} ${1- options.doorway.height/options.height}`,
              lightOffset: `${w3LightOffset} ${options.doorway.height/options.height}`
            }
            ))  



          return entity
        } else {
          const entity  = document.createElement("a-entity");
          const repeat = {x: options.width/2, y:options.height/2}
          const plane = document.createElement("a-plane");
          plane.setAttribute("body", "type: static;shape: plane;")
          plane.setAttribute("position", `${options.x} ${(options.z || 0) +options.height/2} ${-options.y}`)
          plane.setAttribute("rotation", options.rotation)
          plane.setAttribute("width", options.width)
          plane.setAttribute("height", options.height)
          plane.setAttribute("occlude", "true")

          
          if (options.skirting){
            if (options.skirting == true)
              options.skirting = {color: "#DDD"}
            plane.setAttribute("skirting", `color: ${options.skirting.color}`)
          }
          if (options.moulding){
            if (options.moulding == true)
              options.moulding = {color: "#DDD"}
            plane.setAttribute("moulding", `color: ${options.moulding.color}`)
          }
          plane.setAttribute("material", "transparent: true;");

//          if (options.wallLights ){
          const texture =  options.texture ||  {id:"wall1"}
          texture.lightRepeat = options.lightRepeat
          texture.lightOffset = options.lightOffset
            Components.applyTexture(plane, texture, repeat, `${options.roomId}${options.compass}`)
 //         } else {
 //           Components.applyTexture(plane, options.texture ||  {id:"wall1"}, repeat, false)
//          }

          entity.appendChild(plane)
          
          if (options.beams){
            entity.appendChild(this.createWallBeams(options))
          }
          return entity;
        }
      },
      moveIn(compass){
        if (compass == "N")
          return {x: 0, y: -1}
        else if (compass == "S") 
          return {x: 0, y: 1}
        else if (compass == "E") 
          return {x: -1, y: 0}
        else if (compass == "W") 
          return {x: 1, y: 0}
        return {x: 0, y: 0}
      },
      rotate(compass){
        if (compass == "N")
          return  "0, 0, 0"
        else if (compass == "S") 
          return "0, 180, 0"
        else if (compass == "E") 
          return "0, -90, 0"
        else if (compass == "W") 
          return "0, 90, 0"
        return "0, 0, 0"
      },
      flipEW(compass){
        if (compass == "W")
          return  "E"
        else if (compass == "E") 
          return "W"
        return compass
      },
      createWallBeams(options){
        const entity  = document.createElement("a-entity");
        const move = this.moveIn(options.compass)

        options.artStep = options.artStep || (20/3)
        
        if (options.beams.type == "ibeam"){
          const count = (options.width /options.artStep) 
          // N or S compass face
          let start = {x: options.x  - options.width / 2, y: -options.y}
          let step = {x: options.artStep, y: 0}
          if (options.compass == "E" || options.compass == "W"){
            start = {x: options.x , y: -options.y  - options.width / 2}
            step = {x: 0, y: options.artStep}
          }
          const scale = 1
          // default to a size of 0.5
          move.x = move.x * 0.5 * scale
          move.y = move.y * 0.5 * scale

          for (let i = 0; i <= count; i+= 1){

           const repeat = {x:  0.4 * scale, y:options.height/2}
            let cube = document.createElement("a-box");
            cube.setAttribute("position", `${start.x + step.x*i- move.x} ${(options.z || 0)+ options.height/2 } ${start.y + step.y*i + move.y}`)
            cube.setAttribute("rotation", options.rotation)
            cube.setAttribute("width", 0.4 * scale)
            cube.setAttribute("height", options.height)
            cube.setAttribute("depth", 0.11 * scale)
            cube.setAttribute("occlude", "true")
            Components.applyTexture(cube, options.beams.texture || options.texture, repeat)
            entity.appendChild(cube)

            cube = document.createElement("a-box");
            cube.setAttribute("position", `${start.x + step.x*i- move.x*0.1} ${(options.z || 0)+ options.height/2 } ${start.y + step.y*i + move.y*0.1}`)
            cube.setAttribute("rotation", options.rotation)
            cube.setAttribute("width", 0.4 * scale)
            cube.setAttribute("height", options.height)
            cube.setAttribute("depth", 0.11 * scale)
            cube.setAttribute("occlude", "true")
            Components.applyTexture(cube, options.beams.texture || options.texture, repeat)
            entity.appendChild(cube)

            cube = document.createElement("a-box");
            cube.setAttribute("position", `${start.x + step.x*i- move.x*0.5} ${(options.z || 0)+ options.height/2 } ${start.y + step.y*i + move.y*0.5}`)
            cube.setAttribute("rotation", options.rotation)
            cube.setAttribute("width", 0.1 * scale)
            cube.setAttribute("height", options.height)
            cube.setAttribute("depth", 0.4 * scale)
            cube.setAttribute("occlude", "true")
            Components.applyTexture(cube, options.beams.texture || options.texture, repeat)
            entity.appendChild(cube)
          }

        }
        return entity
      },
      createFloor(options){
        const repeat = {x: options.width/2, y:options.depth/2}
        const plane = document.createElement("a-plane");
        plane.setAttribute("body", "type: static;shape: plane;")
        plane.setAttribute("position", `${options.x} ${options.z || 0 } ${-options.y}`)
        plane.setAttribute("rotation", "-90 0 0")
        plane.setAttribute("width", options.width)
        plane.setAttribute("height", options.depth)
        plane.setAttribute("occlude", "true")
        Components.applyTexture(plane, options.texture ||  {id:"floor1"}, repeat, `${options.roomId}F`)
     //   plane.setAttribute("light-map",  `src: #lightFloor;`)


        return plane;
      },
      createCeiling(options){
        const repeat = {x: options.width/8, y:options.depth/8}
        const plane = document.createElement("a-plane");
        plane.setAttribute("position", `${options.x} ${(options.z || 0) + options.height } ${-options.y}`)
        plane.setAttribute("rotation", options.rotation || "90 0 0")
        plane.setAttribute("width", options.width)
        plane.setAttribute("material", "transparent: true;");
        plane.setAttribute("height", options.depth)
        plane.setAttribute("occlude", "true")
        Components.applyTexture(plane, options.texture || {id:"ceiling1"}, repeat)

        return plane;
      },      
      creatRoofLantern(options){

          const depth = options.depth || 0.5

       //   const r = Math.floor(Math.random()*100000)
          const entity = document.createElement("a-entity");

      /*    if (!options.noSpot){
            const lightDest = document.createElement("a-entity");
            lightDest.setAttribute("position", `${options.x+3} ${(options.z || 0)} ${-options.y+1}`)
            lightDest.id = `light${r}`
            entity.appendChild(lightDest)

            const light = document.createElement("a-entity");
            light.setAttribute("position", `${options.x} ${(options.z || 0) + options.height} ${-options.y}`)
            light.setAttribute("light", `type: spot; angle: 30; color: #FFF; intensity: 0.3; castShadow: false;penumbra	:0.5; target:#light${r}`)
            entity.appendChild(light)
          }*/

          const lantern = document.createElement("a-entity");
          lantern.setAttribute("rotation", options.rotation)
          lantern.setAttribute("position", `${options.x} ${(options.z || 0 ) + options.height} ${-options.y}`)
          entity.appendChild(lantern)

          const trans1 = document.createElement("a-plane");
          trans1.setAttribute("position", `0 ${depth - 0.001} 0`)
          trans1.setAttribute("rotation", "90 0 0")
          trans1.setAttribute("width", options.width || 3)
          trans1.setAttribute("height", options.length ||  3)
          trans1.setAttribute("material", "transparent: true; opacity: 0.1")
          lantern.appendChild(trans1)

          const box = document.createElement("a-box");
          box.setAttribute("position", `0 ${depth/2} 0`)
          box.setAttribute("rotation",  "90 180 0")
          box.setAttribute("scale", `${options.width || 3} ${options.length || 3} ${depth}`)
          box.setAttribute("material", "transparent: true; side:back;")
          lantern.appendChild(box)

          const trans2 = document.createElement("a-plane");
          trans2.setAttribute("position", `0  ${-0.001} 0`)
          trans2.setAttribute("rotation", "90 0 0")
          trans2.setAttribute("width", options.width || 3)
          trans2.setAttribute("height", options.length ||  3)
          trans2.setAttribute("material", "transparent: true; opacity: 0.1")
          lantern.appendChild(trans2)


          return entity;
      } ,
      creatRoofPendant(options){
        const entity = document.createElement("a-entity");

        const cone = document.createElement("a-cone");
        cone.setAttribute("position", `${options.x} ${(options.z || 0) + options.height-3.5} ${-options.y}`)
        cone.setAttribute("radius-top", `0.01`)
        cone.setAttribute("radius-bottom", `1`)
        cone.setAttribute("height", 1)
        cone.setAttribute("color", "#333")
        cone.setAttribute("open-ended", true)
        cone.setAttribute("side", "front")

        entity.appendChild(cone)


        const coneInner = document.createElement("a-cone");
        coneInner.setAttribute("position", `${options.x} ${(options.z || 0) + options.height-3.5} ${-options.y}`)
        coneInner.setAttribute("radius-top", `0.01`)
        coneInner.setAttribute("radius-bottom", `1`)
        coneInner.setAttribute("height", 1)
        coneInner.setAttribute("open-ended", true)
        coneInner.setAttribute("side", "back")
        Components.applyTexture(coneInner, {id:"Light"}, {x:1, y: 1})

        entity.appendChild(coneInner)        

        const cube = document.createElement("a-box");
        cube.setAttribute("position", `${options.x} ${(options.z || 0) + options.height -1.5} ${-options.y}`)
        cube.setAttribute("width", 0.1)
        cube.setAttribute("depth", 0.1)
        cube.setAttribute("height", 3)
        cube.setAttribute("color", "#999")

        entity.appendChild(cube)
        

        const cube2 = document.createElement("a-box");
        cube2.setAttribute("position", `${options.x} ${(options.z || 0) + options.height -0.05} ${-options.y}`)
        cube2.setAttribute("width", 0.3)
        cube2.setAttribute("depth", 0.3)
        cube2.setAttribute("height", 0.1)
        cube2.setAttribute("color", "#555")

        entity.appendChild(cube2)        
        const r = Math.floor(Math.random()*100000)

       const lightDest = document.createElement("a-entity");
        lightDest.setAttribute("position", `${options.x} ${(options.z || 0)} ${-options.y+1}`)
        lightDest.id = `light${r}`
        entity.appendChild(lightDest)

        const light = document.createElement("a-entity");
        light.setAttribute("position", `${options.x} ${(options.z || 0) + options.height} ${-options.y}`)
        light.setAttribute("light", `type: spot; angle: 20; color: #FFF; intensity: 0.4; castShadow: false;penumbra	:0.5; target:#light${r}`)
        entity.appendChild(light)



        return entity

      },
      createBeam(options){

        const repeat = {x: options.width/2, y:options.depth/2}
        const cube = document.createElement("a-box");
        cube.setAttribute("position", `${options.x} ${options.z || 0 } ${-options.y}`)
        cube.setAttribute("rotation", options.rotation)
        cube.setAttribute("width", options.width)
        cube.setAttribute("height", options.height)
        cube.setAttribute("depth", options.depth)
        cube.setAttribute("occlude", "true")
        Components.applyTexture(cube, options.texture ||  {id:"ceiling1"}, repeat)

        return cube;
      },   
      createBench(options){
        const bench = document.createElement("a-entity");
        const cube = document.createElement("a-box");
        cube.setAttribute("position", `${options.x} ${(options.z || 0)+0.8 } ${-options.y}`)
        cube.setAttribute("rotation", options.rotation)
        cube.setAttribute("width", 1.5)
        cube.setAttribute("height", 0.2)
        cube.setAttribute("depth", 3)
        cube.setAttribute("occlude", "true")
        Components.applyTexture(cube, {id:"Leather_Perforated_001"}, {x: 3, y: 5})
        bench.appendChild(cube)

        let legs = [document.createElement("a-box"),document.createElement("a-box"),document.createElement("a-box"),document.createElement("a-box"), document.createElement("a-box"), document.createElement("a-box")]
        legs[0].setAttribute("position", `${options.x+0.6} ${(options.z || 0)+0.4 } ${-options.y+1.4}`)
        legs[1].setAttribute("position", `${options.x-0.6} ${(options.z || 0)+0.4 } ${-options.y+1.4}`)
        legs[2].setAttribute("position", `${options.x-0.6} ${(options.z || 0)+0.4 } ${-options.y-1.4}`)
        legs[3].setAttribute("position", `${options.x+0.6} ${(options.z || 0)+0.4 } ${-options.y-1.4}`)
        legs[4].setAttribute("position", `${options.x-0.6} ${(options.z || 0)+0.4 } ${-options.y}`)
        legs[5].setAttribute("position", `${options.x+0.6} ${(options.z || 0)+0.4 } ${-options.y}`)
        legs.forEach( (leg) => {
          leg.setAttribute("rotation", options.rotation)
          leg.setAttribute("width", 0.1)
          leg.setAttribute("height", 0.8)
          leg.setAttribute("depth", 0.1)
          leg.setAttribute("occlude", "true")
          leg.setAttribute("color", "#AAA")
          leg.setAttribute("metalness", 0.5)
          bench.appendChild(leg)
        });
        return bench;
      },
      createPedistool(options){
        const pedistool = document.createElement("a-entity");
        const cube = document.createElement("a-cone");
        cube.setAttribute("body", "type: static;shape: cube;")
        cube.setAttribute("position", `${options.x} ${(options.z || 0)+0.5 } ${-options.y}`)
        cube.setAttribute("rotation", options.rotation)
        cube.setAttribute("radius-top", `5`)
        cube.setAttribute("radius-bottom", `5`)
        cube.setAttribute("width", 10)
        cube.setAttribute("height", 1)
        cube.setAttribute("depth", 10)
        cube.setAttribute("occlude", "true")
        Components.applyTexture(cube, {id:"Metal_Plate_048"}, {x: 4, y: 4})
        pedistool.appendChild(cube)


        return pedistool;
      },
      createDisplayBoards(options){
        const boards = document.createElement("a-entity");
        options.displayBoards.forEach( (board) => {

          const cube = document.createElement("a-box");
          cube.setAttribute("position", `${board.x} ${(options.z || 0)+board.height/2 } ${-board.y}`)
          cube.setAttribute("body", "type: static;shape: box;")
          cube.setAttribute("rotation", this.rotate(board.direction))
          cube.setAttribute("width", board.width)
          cube.setAttribute("height", board.height)
          cube.setAttribute("depth", board.depth)
          cube.setAttribute("occlude", "true")
          cube.setAttribute("skirting", "color: #555")
          Components.applyTexture(cube, board.texture, {x: board.width, y: board.height}, `wallLights1`)
          boards.appendChild(cube)
        })
        return boards

      },
            
      createWindows(options){

        const entity = document.createElement("a-entity");

        options.walls.windows.forEach( (window) => {


          const depth = window.depth || 0.5

          const r = Math.floor(Math.random()*100000)

         if (window.light){
            const lightDest = document.createElement("a-entity");
            lightDest.setAttribute("position", `${options.x+3} ${(options.z || 0)} ${-options.y+1}`)
            lightDest.id = `light${r}`
            entity.appendChild(lightDest)

            const light = document.createElement("a-entity");
            light.setAttribute("position", `${options.x} ${(options.z || 0) + options.height} ${-options.y}`)
            light.setAttribute("light", `type: spot; angle: 30; color: #FFF; intensity: 0.3; castShadow: false;penumbra	:0.5; target:#light${r}`)
            entity.appendChild(light)
         }

          const lantern = document.createElement("a-entity");
          lantern.setAttribute("rotation", this.rotate(window.direction))
          lantern.setAttribute("position", `${window.x} ${(options.z || 0 ) + window.z} ${-window.y}`)
          entity.appendChild(lantern)

          const trans1 = document.createElement("a-plane");
          trans1.setAttribute("position", `0 0 ${-depth + 0.01}`)
       //   trans1.setAttribute("rotation", "90 0 0")
          trans1.setAttribute("width", window.width)
          trans1.setAttribute("height", window.height)
          trans1.setAttribute("material", "transparent: true;opacity:0.6 ")
          Components.applyTexture(trans1, window.texture, {x: window.width, y: window.height})

          lantern.appendChild(trans1)

          const box = document.createElement("a-box");
          box.setAttribute("position", `0 0 ${-depth/2}`)
          box.setAttribute("rotation",  "0 180 0")
          box.setAttribute("scale", `${window.width} ${window.height} ${depth+0.1}`)
          box.setAttribute("material", "transparent: true; side:back;")
          Components.applyTexture(box, options.walls.texture, {x: depth, y: window.height})
          lantern.appendChild(box)

       const trans2 = document.createElement("a-plane");
       trans2.setAttribute("position", `0  0 ${-depth/2}`)
       trans2.setAttribute("rotation", "0 0 0")
       trans2.setAttribute("width", window.width)
       trans2.setAttribute("height", window.height)
       trans2.setAttribute("material", "transparent: true; opacity: 0.4")
        Components.applyTexture(trans2, window.texture, {x: window.width, y: window.height})
        lantern.appendChild(trans2)

          const trans3 = document.createElement("a-plane");
          trans3.setAttribute("position", `0  0 ${0.01}`)
          trans3.setAttribute("rotation", "0 0 0")
          trans3.setAttribute("width", window.width)
          trans3.setAttribute("height", window.height)
          trans3.setAttribute("material", "transparent: true; opacity: 0")
//          Components.applyTexture(trans2, window.texture, {x: window.width, y: window.height})
          lantern.appendChild(trans3)

        })


        return entity;
    } ,
}

export default Gallery