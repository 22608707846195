<template>
  <div>
    <v-snackbar v-model="snackbar" v-if="currentArt">
      {{ currentArt.name || currentArt.meta.name }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="artDlg = true">
          Open
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog class="instructionDlg" v-if="!$vuetify.breakpoint.mobile" v-model="instructionDlg" max-width=800>
      <v-card>
        <v-card-title>
          Welcome
        </v-card-title>

        <v-card-text class="mt-3">
          <v-row>
            <v-col cols=8>
              <p class="text-h5"> To navigate use mouse to look around and arrow keys or W,S,A,D to move</p>
              <p class="text-h6"> Press escape to get your mouse pointer </P>
            </v-col>
            <v-col cols=4>
              <v-img contain src="controls.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="instructionDlg = false">
            Close
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="artDlg" max-width=850>
      <v-card v-if="currentArt">
        <v-card-title class="galleryAsset__title">
          <v-img v-if="currentArt.nft" max-width="40" class="mr-2" src="/nft.png"></v-img>
          {{ currentArt.name || (currentArt.nft && currentArt.nft.name) }}
          <v-spacer></v-spacer>
          <v-btn small fab link :to="{ name: routerName, params: routerParams, query: { picture: currentArt.id } }"
            target=_blank>

            <v-icon>mdi-link</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-3 px-3">
          <v-row>
            <v-col cols=12 md=6 class="galleryAsset__image">
              <div>
                <div v-if="currentArt.assetType == 'Video'">
                  <v-row>
                    <video ref="assetVideo" :src="currentArt.url" controls :autoplay="autoplay" loop
                      width="100%"></video>
                  </v-row>
                  <v-row>
                    <v-btn class="mt-2" small color="white" @click="fullScreen()">
                      <v-icon>mdi-fullscreen</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-switch class="float-right" small dense v-model="autoplay" label="Autoplay"></v-switch>
                  </v-row>
                </div>
                <div v-else-if="currentArt.image">
                  <img ref="assetImage" :src="currentArt.image" width="100%">
                  <v-btn small class="mt-2" color="white" @click="fullScreen()">
                    <v-icon>mdi-fullscreen</v-icon>
                  </v-btn>
                </div>
                <div v-else>
                  <img ref="assetImage" :src="currentArt.url" width="100%">
                  <v-btn small class="mt-2" color="white" @click="fullScreen()">
                    <v-icon>mdi-fullscreen</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols=12 md=6 class="galleryAsset__description">
              <h3 class="text-h5" v-if="currentArt.meta">{{ currentArt.meta.name }}</h3>
              <h3 class="text-h5" v-if="currentArt.artist">Artist: {{ currentArt.artist }}</h3>
              <p v-if="currentArt.description">{{ currentArt.description  }}</p>
              <v-divider></v-divider>
              <div class="ma-1" v-if="currentArt.meta && currentArt.meta.properties">
                <h5>Attributes</h5>
                <v-card v-for="(prop, idx) in currentArt.meta.properties" :key="idx" class="props-card "
                  outlined color="green" dark>
                  <v-list-item two-line class="px-1">
                    <v-list-item-content class="py-0">
                      <div class="mb-0">
                        {{ prop.trait_type }}
                      </div>
                      <v-list-item-title class="text mb-0">
                        {{ prop.value }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <br clear="all" />
                <v-divider></v-divider>
              </div>
              <div class="ma-3" v-if="currentArt.social">
                <v-btn v-if="currentArt.social.discord" link :href="currentArt.social.discord" icon>
                  <v-icon>mdi-discord</v-icon>
                </v-btn>
                <v-btn v-if="currentArt.social.twitter" link :href="currentArt.social.twitter" icon>
                  <v-icon>mdi-twitter</v-icon>
                </v-btn>
                <v-btn v-if="currentArt.social.facebook" link :href="currentArt.social.facebook" icon>
                  <v-icon>mdi-facebook</v-icon>
                </v-btn>
                <v-btn v-if="currentArt.social.instagram" link :href="currentArt.social.instagram" icon>
                  <v-icon>mdi-instagram</v-icon>
                </v-btn>
              </div>
              <div class="ma-3" v-if="currentArt.purchaseLink || currentArt.shopLink">
                Buy now: <br />
                <v-btn class="ma-4 pa-4" v-if="currentArt.shopLink" color="white" link :href="currentArt.shopLink"
                  target=_blank>
                  Shop
                  <span v-if="currentArt.price && currentArt.price.usd"> - ${{ currentArt.price.usd }}</span>
                  <span v-if="currentArt.price && currentArt.price.gbp"> - ${{ currentArt.price.gbp }}</span>
                </v-btn>
                <v-btn class="ma-4 pa-4" v-if="currentArt.purchaseLink" color="white" link
                  :href="currentArt.purchaseLink" target=_blank>
                  Purchase
                  <span v-if="currentArt.price && currentArt.price.eth"> - ${{ currentArt.price.eth }}</span>
                </v-btn>
              </div>
              <div class="ma-3" v-if="currentArt.viewLink">
                <v-btn class="ma-4 pa-4" v-if="currentArt.viewLink" color="white" link :href="currentArt.viewLink"
                  target=_blank>View </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-btn v-if="currentArt.nft" class="teal darken-1 white--text" @click="openSea(currentArt)">View on Opensea</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="artDlg = false">
            Close
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <a-scene ref="scene" renderer="logarithmicDepthBuffer: true;" :stats="stats" light="defaultLightsEnabled: false"
      v-if="loaded && metaverse == 'gallery'" cursor="rayOrigin: mouse" raycaster="objects: .art"
      loading-screen="dotsColor: red; backgroundColor: black">
      <a-assets @loaded="assetsLoaded()" ref="assets">
        <img v-for="asset in assets" :key="asset.id" :id="asset.id" :ref="asset.id" :src="asset.path" @load="loadTexture(asset)">
      </a-assets>

      <a-entity id="rig" ref="rig" movement-controls body="type: static;shape: box;" kinematic-body position="0 0 -3"
        rotation="0 0 0">
        <a-entity id="camera" camera="fov:70" position="0 1.9 0" ref="camera" look-controls="pointerLockEnabled: false">
        </a-entity>
      </a-entity>

      <a-entity light="type: ambient; color: #FED; intensity: 0.8"></a-entity>
      <a-entity light="type: directional; color: #FFE; intensity: 0.7" position="-0.5 1 1"></a-entity>
      <a-entity light="type: directional; color: #FFE; intensity: 0.6" position="0.5 1 -1"></a-entity>
      <a-entity light="type: directional; color: #FFE; intensity: 0.2" position="0.5 -1 0"></a-entity>

      <!--
            <a-entity scale=".3 .3 .3" rotation="0 0 0" position="0 0 -10" animation-mixer gltf-model="url(/textures/gltf/60af6098e5e129b6f1624a1816bc122a.glb)"></a-entity>

            <a-entity scale=".03 .03 .03" rotation="0 0 0" position="25 0 -10" animation-mixer gltf-model="url(/textures/gltf/8197.glb)"></a-entity>
            <a-entity scale="2 2 2" rotation="0 0 0" position="0 1 -10" animation-mixer gltf-model="url(/textures/gltf/f1.glb)"></a-entity>
-->

      <a-sky id="sky" color="#FFF" src="#sky" segments-width="16" segments-height="15"></a-sky>
    </a-scene>


  </div>
</template>

<script>

import Components from '@/components/aframe/components'
import Gallery from '@/components/aframe/gallery'
import Rooms from '@/components/aframe/rooms'
import Maze from '@/components/aframe/maze'
import LightMap from '@/components/aframe/lightMap'
//import firebase from '@/plugins/firebase'
import Vue from 'vue'
import PA from '@proveanything/base'


export default {
  name: 'HelloWorld',
  props: {
  },
  data: () => ({
    currentArt: null,
    artDlg: false,
    snackbar: false,
    autoplay: true,
    assets: [],
    id: 0,
    routerName: "",
    routerParams: {},
    gallerySetup: null,
    positions: [],
    loaded: false,
    stats: false,
    metaverse: "gallery",
    currentSquare: null,
    squareDlg: false,
    nftSquare: {},
    instructionDlg: true,
    editorMode: false,
    autofill: false,
    frameMap: []
  }),
  watch: {
    '$route.params.id': function () {
      window.location.reload();
    },
    '$route.query.autofill': function () {
      window.location.reload();
    },
    '$route.query.picture': function () {
      // window.location.reload();
      this.setPictureLocation(parseInt(this.$route.query.picture))
    },
    '$route.query.stats': function () {
      this.stats = this.$route.query.stats
    },
    'artDlg': function () {
      //reset velocity and keys
      this.$refs.rig.components['keyboard-controls'].localKeys = {}
    }
  },
  async mounted() {

    if (this.$route.query.editor) {
      this.editorMode = true
    }
    addEventListener("message", (event) => {
      if (event.data && event.data.galleryAction) {
        if (event.data.galleryAction == "moveToPicture"){
          this.placeArt = event.data.art
          this.setPictureLocation(event.data.picture)
        } else if (event.data.galleryAction == "updateArtProperties") {
          this.updateArtProperties(event.data.picture, event.data.data)
        } else if (event.data.galleryAction == "removeArt") {
          this.removeArt(event.data.frameId)
        } else if (event.data.galleryAction == "reload"){
          window.location.reload();
        }
      }
    });
    this.instructionDlg = false

    // support various routing versions
    this.id = this.$route.params.id
    this.routerParams.id = this.id
    if (this.$route.params.root) {
      this.routerName = "rootview"
      this.routerParams.root = this.$route.params.root
    }
    if (this.$route.query.autofill) {
      this.autofill = this.$route.query.autofill == "1"
    }

    this.stats = this.$route.query.stats

    this.loadRoomsMetaverse();

    //this.loadMazeMetaverse();    

  },
  methods: {
    async loadMazeMetaverse() {

      this.art = []

      // get the art
      await this.loadArt()

      this.loaded = true;

      Vue.nextTick(() => {
        const scene = this.$refs.scene;

        if (!this.gallerySetup) {
          this.gallerySetup = {
            textures: [
              { id: "Stone_Tiles_004", name: "Stone_Tiles_004", metal: false, rough: true, repeat: 1 },
              { id: "Watercolor_Paper_001", name: "Watercolor_Paper_001", metal: false, rough: true, repeat: 1, normalScale: 0.3 }
            ]
          }
        }
        this.gallerySetup.textures.forEach((texture) => {
          this.addTexture(texture)
        })


        let maze = this.$route.query.maze
        maze ||= "1"

        this.positions = Maze.load({ scene, maze })

        this.addArt(true)


      });
    },
    async loadGalleryMetaverse() {
      Components.setup()
      Gallery.configureHall()
    },
    async loadTexture(){

    //  console.log(asset,this.$refs);


    },
    async assetsLoaded(){
      Object.keys(Components.lightmaps).forEach( (id) => {
        if (Components.lightmaps[id].ao){
          const maps = Components.lightmaps[id].ao
          maps.forEach( (map) => {
            map.img = this.$refs[map.id][0]
          })
          LightMap.generate(id + "AO", maps, this.$refs.assets)
        }
        if (Components.lightmaps[id].light){
          const maps = Components.lightmaps[id].light
          maps.forEach( (map) => {
            map.img = this.$refs[map.id][0]
          })
          LightMap.generate(id + "L", maps, this.$refs.assets)
        }
      })
    },
    async loadRoomsMetaverse() {
      this.assets = [
      ]
      this.assets.push({ id: `sky`, path: `/textures/sea.jpg` })
      this.assets.push({ id: `wallLight`, path: `/textures/downLight.jpg` })
      this.assets.push({ id: `wallLights3`, path: `/textures/wallLights3.jpg` })
      this.assets.push({ id: `wallLights2`, path: `/textures/wallLights2.jpg` })
      this.assets.push({ id: `wallLights1`, path: `/textures/wallLights1.jpg` })
      this.assets.push({ id: `wallLights0`, path: `/textures/wallLights0.jpg` })
      this.assets.push({ id: `shaft`, path: `/textures/shaft1.jpg` })
      this.assets.push({ id: `rshaft`, path: `/textures/rshaft1.jpg` })
      this.assets.push({ id: `darkCorner`, path: `/textures/darkCorner.jpg` })
    //  this.assets.push({ id: `wallLights3`, path: `/textures/shaft.jpg`, maps: true })
      this.assets.push({ id: `shaftFloor`, path: `/textures/shaftFloor.jpg` })
      this.assets.push({ id: `skylightFloor`, path: `/textures/skylightFloor.jpg` })
      this.addTexture({ id: "Leather_Perforated_001", name: "bench", maps: ["ao", "normal", "rough"] })

      this.art = []

      // get the art
      await this.loadArt()

      this.loaded = true;

      Vue.nextTick(() => {
        if (!this.gallerySetup) {
          this.gallerySetup = {
            rooms: [
              /*
              {
                roof: {
                  type: "warehouse", 
                  roofLights: "pendant", 
                  texture: {id:"Metal_Corrugated_009"},
                  textureBeam:{id:"Watercolor_Paper_001", normalScale: "0.2", color:"#99A"}
                  },
                height: 10,
                width: 40,
                depth: 60,
                walls: {
                  EWbeams: {type: "ibeam",  texture:{id:"Watercolor_Paper_001", normalScale: "0.2", color:"#99A"}},
                  moulding: {color:'#444'},
                  skirting: {color:'#444'},
                  lights: true,
                  texture: {id: "Metal_Corrugated_009", color: "#FFF"},
                  textureNS: {id: "Wall_Stone_022", color: "#FFF", repeat: 0.5},
                  windows: [
                   {x: 10, y:40, z: 7, direction: "N", width: 6, height: 4, texture: {id: "Glass_Blocks_001", color: "#FFF", repeat: 0.5}},
                   {x: -10, y:40, z: 7, direction: "N", width: 6, height: 4, texture: {id: "Glass_Blocks_001", color: "#FFF", repeat: 0.5}},
                  ]
                },
                centre: {
                  type: "pedistool"
                },
                floor: {
                  texture: { id: "Concrete_Blocks_004"}
                },
                displayBoards: [
                  {x: 5, y:20, width: 5, height: 4, direction: "E", depth: 0.2, texture: {id: "Watercolor_Paper_001", color: "#0a5a4f"}},
                  {x: -5, y:20, width: 5, height: 4, direction: "E", depth: 0.2, texture: {id: "Watercolor_Paper_001", color: "#0a5a4f"}},
                  {x: 5, y:0, width: 5, height: 4, direction: "E", depth: 0.2, texture: {id: "Watercolor_Paper_001", color: "#0a5a4f"}},
                  {x: -5, y:0, width: 5, height: 4, direction: "E", depth: 0.2, texture: {id: "Watercolor_Paper_001", color: "#0a5a4f"}},
                  {x: 0, y:30, width: 5, height: 4, direction: "N", depth: 0.2, texture: {id: "Watercolor_Paper_001", color: "#0a5a4f"}},
                  {x: 0, y:-10, width: 5, height: 4, direction: "N", depth: 0.2, texture: {id: "Watercolor_Paper_001", color: "#0a5a4f"}},
                ]
              },
              */
              {
                roof: { type: "flat", roofLights: "12", texture: { id: "Watercolor_Paper_001" } },
                height: 6,
                walls: {
                  moudling: true,
                  skirting: true,
                  lights: true,
                  texture: { id: "Watercolor_Paper_001", normalScale: 0.3, color: "#611" }
                },
                floor: {
                  texture: { id: "Stone_Tiles_004" }
                }
              },
              {
                roof: { type: "apex", roofLights: "12", texture: { id: "Watercolor_Paper_001" } },
                height: 8,
                walls: {
                  moudling: true,
                  skirting: true,
                  lights: true,
                  texture: { id: "Watercolor_Paper_001", normalScale: 0.3, color: "#611" }
                },
                floor: {
                  texture: { id: "Stone_Tiles_004" }
                }
              }
            ],
            textures: [
              { id: "Stone_Tiles_004", name: "Stone_Tiles_004", maps: ["ao", "normal", "rough"] },
              { id: "Watercolor_Paper_001", name: "Watercolor_Paper_001", maps: ["ao", "normal", "rough"] },
              { id: "Concrete_007", name: "Concrete_007", maps: ["ao", "normal"] },
              { id: "Concrete_Blocks_004", name: "Concrete_Blocks_004", maps: ["ao", "normal", "rough"] },
              { id: "Metal_Plate_048", name: "Metal_Plate_048", maps: ["ao", "normal", "rough", "metal"] },
              { id: "Metal_Corrugated_009", name: "Metal_Corrugated_009", maps: ["ao", "normal"] },
              { id: "Wall_Stone_022", name: "Wall_Stone_022", maps: ["ao", "normal", "rough"] },
              { id: "Glass_Blocks_001", name: "Glass_Blocks_001", maps: ["ao", "normal", "rough", "alpha"] },
              { id: "Light", name: "Light", maps: ["emissive"] },
            ]
          }
        }



        this.gallerySetup.textures.forEach((texture) => {
          this.addTexture(texture)
        })

        this.addRooms();


        this.addArtPositions()

        Components.setup()

        this.addArt()
      });
    },
    async loadArt() {


      // this is either set up to get an art image for each proofGroup
      // or to get all art associated with a specific nft in a specifc proof group
      if (this.$route.params.proofGroup == "all") {

        const collection = await PA.Core.Collection.get(this.$route.params.collection)

        this.gallerySetup = collection.gallerySetup;

        const groups = await PA.Core.ProofGroup.getAll(this.$route.params.collection)

        groups.forEach((group) => {
          if (group.heroImage) {
            this.art.push({
              name: group.name,
              artist: collection.description,
              description: group.description,
              url: group.heroImage.url,
              proofGroup: group.id,
              site: group.site,
              purchaseLink: `https://opensea.io/assets/matic/${collection.chain.contract}/${group.index}`,
              shopLink: group.shopLink,
              price: group.price,
              height512: group.height512,
              width512: group.width512,
              thumbnails: {}
            })
          }

        })
      } else {
        const nft = await await PA.Core.Proof.get(this.$route.params.collection, this.$route.params.proofGroup, this.id)

        this.gallerySetup = (nft && nft.meta) ? nft.meta.gallerySetup : null;


        this.art = await PA.Core.Proof.getDocuments(this.$route.params.collection, this.$route.params.proofGroup, this.id)


        //  if (nft && nft.meta && nft.meta.art){
        //   this.art = nft.meta.art
        //  }
      }
    },
    addArt(fill = false) {

      if (this.art && this.art.length > 0) {

        if (fill) {
          let count = 0;
          this.positions.forEach((pos) => {

            const art = this.art[count];

            pos.scene = this.$refs.scene;
            pos.src = art.thumbnails.x512 || art.url
            pos.artId = art.id
            pos.imgHeight = (art.height512 || 512) / 512
            pos.imgWidth = (art.width512 || 512) / 512
            pos.frame = art.frame

            const elm = Rooms.addArt(pos)
            elm.addEventListener('click', this.openArt.bind(this))
            elm.addEventListener('mouseenter', this.previewArt.bind(this))
            count += 1;
            if (count >= this.art.length)
              count = 0;
          })
        } else {

          let pos = 0;
          this.currentArt = this.art[0]


          this.art.forEach((art) => {
            if (art.frameId) {
              this.assignArtToPosition(this.frameMap[art.frameId], art)
            }
          });
          if (this.autofill) {
            this.art.forEach((art) => {
              if (this.positions.length > pos && art.url) {
                this.assignArtToPosition(this.positions[pos], art)
                pos += 1;
              }
            });
          }
          if (this.editorMode) {
            this.positions.forEach((pos) => {
              if (!pos.artId) {
                this.assignBlankToPosition(pos)
              }
            })
          }
        }
      }
      if (this.$route.query.picture) {
        this.setPictureLocation(this.$route.query.picture)
      }

    },
    addRooms() {

      // quick fix for lights

      let roomNum = 0;
      let roomTotal = this.gallerySetup.rooms.length
      let left = -(this.gallerySetup.rooms[0].width || 20) / 2
      let back = (this.gallerySetup.rooms[0].back || 20);  // and adjustment to the depth to make sure that one room connects to the next
      this.gallerySetup.rooms.forEach((room) => {
        room.scene = this.$refs.scene,
          room.id = `room${roomNum + 1}`
        room.width = room.width || 20
        room.depth = room.depth || 20
        room.y = back - room.depth / 2
        room.x = left + room.width / 2
        room.doorways = {}
        if (roomNum < roomTotal - 1) {
          room.doorways.east = { width: 4, height: 4, position: -(room.depth / 2) + 5, corridor: true }
        }
        if (roomNum > 0) {
          room.doorways.west = { width: 4, height: 4, position: -(room.depth / 2) + 5 }
        }
        left += room.width + 5

        Components.addLightmap(`${room.id}N`, {
          ao:[
            {id: "darkCorner", x: 0, y: 0},
          ], 
          light: [
          ]
        })
        Components.addLightmap(`${room.id}S`, {
          ao:[
            {id: "darkCorner", x: 0, y: 0},
          ], 
          light: [
          ]
        })
        Components.addLightmap(`${room.id}E`, {
          ao:[
             {id: "darkCorner", x: 0, y: 0},
          ], 
          light: [
          ]
        })
        Components.addLightmap(`${room.id}W`, {
          ao:[
            {id: "darkCorner", x: 0, y: 0},
          ], 
          light: [
          ]
        })        
        Components.addLightmap(`${room.id}F`, {ao:[
          {id: "darkCorner", x: 0, y: 0},
        ]})

        if (room.roof.roofLights == "strip"){
          Components.lightmaps[`${room.id}F`].ao.push({id: "shaftFloor", x: 500, y: 0, height: 1024, width: 300})
          Components.lightmaps[`${room.id}F`].ao.push({id: "shaftFloor", x: 1300, y: 0, height: 1024, width: 300})
          Components.lightmaps[`${room.id}S`].ao.push({id: "rshaft", x: 450, y: 0,  height: 1024, width: 500})
          Components.lightmaps[`${room.id}S`].ao.push({id: "rshaft", x: 1230, y: 0,  height: 1024, width: 500})
          Components.lightmaps[`${room.id}N`].ao.push({id: "shaft", x: 320, y: 0,  height: 1024, width: 500})
          Components.lightmaps[`${room.id}N`].ao.push({id: "shaft", x: 1090, y: 0,  height: 1024, width: 500})
        }
        if (room.roof.roofLights == "4"){
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 500, y: 200, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 500, y: 700, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1500, y: 200, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1500, y: 700, height: 200, width: 400})
        }
        if (room.roof.roofLights == "8"){
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 800, y: 250, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 800, y: 400, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 800, y: 550, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 800, y: 700, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1100, y: 250, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1100, y: 400, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1100, y: 550, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1100, y: 700, height: 200, width: 400})
        }
        if (room.roof.roofLights == "12"){
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 500, y: 250, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 500, y: 400, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 500, y: 550, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 500, y: 700, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 800, y: 250, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 800, y: 400, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 800, y: 550, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 800, y: 700, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1100, y: 250, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1100, y: 400, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1100, y: 550, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1100, y: 700, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1400, y: 250, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1400, y: 400, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1400, y: 550, height: 200, width: 400})
          Components.lightmaps[`${room.id}F`].ao.push({id: "skylightFloor", x: 1400, y: 700, height: 200, width: 400})
        }

        Rooms.addRoom(room)
        roomNum += 1

      })
    },
    addArtPositions() {

      this.positions = []
      let roomId = 1
      this.gallerySetup.rooms.forEach((room) => {
        const x = room.x
        const y = room.y

        const width = room.width
        const depth = room.depth
        const height = 5 // picture height

        // the boards first
        let count = 1
        if (room.displayBoards) {
          room.displayBoards.forEach((board) => {
            if (board.direction == "E" || board.direction == "W") {
              this.positions.push({ id: `R${roomId}B${count}E1`, x: board.x - board.depth / 2, y: board.y, height, direction: "E", room: room })
              this.positions.push({ id: `R${roomId}B${count}W1`, x: board.x + board.depth / 2, y: board.y, height, direction: "W", room: room })
            } else {
              this.positions.push({ id: `R${roomId}B${count}N1`, x: board.x, y: board.y - board.depth / 2, height, direction: "N", room: room })
              this.positions.push({ id: `R${roomId}B${count}S1`, x: board.x, y: board.y + board.depth / 2, height, direction: "S", room: room })
            }
            count += 1;
          });
        }
        // north frames
        const gap = 20 / 3 // default is 6.66m
        count = 1
        for (let i = gap / 2; i < width; i += gap) {
          this.positions.push({ id: `R${roomId}W1N${count}`, x: x - width / 2 + i, y: y + depth / 2, height, direction: "N", room: room, lightOffset:  i })
          count += 1
        }
        const dgap = 20 / 3
        // show one less if there is a door
        let eastDepth = room.doorways.east ? (depth - dgap) : depth
        count = 1
        for (let i = dgap / 2; i < eastDepth; i += dgap) {
          this.positions.push({ id: `R${roomId}W1E${count}`, x: x + width / 2, y: y - depth / 2 + i, height, direction: "E", room: room, lightOffset: room.depth -  i })
          count += 1
        }
        // south frames
        count = 1
        for (let i = gap / 2; i < width; i += gap) {
          this.positions.push({ id: `R${roomId}W1S${count}`, x: x - width / 2 + i, y: y - depth / 2, height, direction: "S", room: room, lightOffset: room.width -i })
          count += 1
        }
        let westDepth = room.doorways.west ? (depth - dgap) : depth

        count = 1
        for (let i = dgap / 2; i < westDepth; i += dgap) {
          this.positions.push({ id: `R${roomId}W1W${count}`, x: x - width / 2, y: y - depth / 2 + i, height, direction: "W", room: room, lightOffset: i })
          count += 1
        }
        roomId += 1;
      });
      this.frameMap = {}

      this.positions.forEach((pos) => {
        this.frameMap[pos.id] = pos
        if (pos.direction == "N") {
          pos.rotation = "0, 0, 0"
          pos.viewOffset = { x: 0, y: -5, z: 0 }
        } else if (pos.direction == "E") {
          pos.viewOffset = { x: -5, y: 0, z: 0 }
          pos.rotation = "0, -90, 0"
        } else if (pos.direction == "S") {
          pos.rotation = "0, 180, 0"
          pos.viewOffset = { x: 0, y: 5, z: 0 }
        } else if (pos.direction == "W") {
          pos.rotation = "0, 90, 0"
          pos.viewOffset = { x: 5, y: 0, z: 0 }
        }
      })

    },
    updateArtProperties(artId, values) {
      const pos = this.positions.find(x => x.artId == artId)
      const art = this.art.find(x => x.id == artId)
      if (art) {
        Object.assign(art, values)
        console.log("update", values, art)
        this.assignArtToPosition(pos, art)

      }
    },
    setPictureLocation(artId) {

      const art = this.positions.find(x => x.artId == artId)

      if (art) {
        this.$refs.rig.setAttribute('position', `${art.x + art.viewOffset.x} ${art.viewOffset.z}, -${art.y + art.viewOffset.y}`)
        this.$refs.rig.setAttribute('rotation', art.rotation)
        // reset the mouse pitch and yaw
        let controls = this.$refs.camera.components['look-controls']
        if (controls.pitchObject)
          controls.pitchObject.rotation.x = 0
        if (controls.yawObject)
          controls.yawObject.rotation.y = 0
        this.placeArtId = artId
      } else {
        this.placeArtId = artId;
      }

    },
    addTexture(options) {

      const id = options.id

      this.assets.push({ id: `${id}`, path: `/textures/${id}/${id}_basecolor.jpg` })
      if (options.maps.includes("ao")) {
        this.assets.push({ id: `${id}-ambient`, path: `/textures/${id}/${id}_ambientOcclusion.jpg` })
      }
      if (options.maps.includes("normal")) {
        this.assets.push({ id: `${id}-normal`, path: `/textures/${id}/${id}_normal.jpg` })
      }
      if (options.maps.includes("metal")) {
        this.assets.push({ id: `${id}-metal`, path: `/textures/${id}/${id}_metallic.jpg` })
      }
      if (options.maps.includes("rough")) {
        this.assets.push({ id: `${id}-rough`, path: `/textures/${id}/${id}_roughness.jpg` })
      }
      if (options.maps.includes("alpha")) {
        this.assets.push({ id: `${id}-alpha`, path: `/textures/${id}/${id}_opacity.jpg` })
      }
      if (options.maps.includes("emissive")) {
        this.assets.push({ id: `${id}-emissive`, path: `/textures/${id}/${id}_emissive.jpg` })
      }
      if (options.maps.includes("height")) {
        this.assets.push({ id: `${id}-height`, path: `/textures/${id}/${id}_height.png` })
      }
      Components.configureTexture(options)
    },
    async getDefaultAsset(art) {
      const defaultAsset = await PA.Core.Asset.getDefault(art.site, art.proofGroup)

      if (defaultAsset) {
        art.assetType = defaultAsset.assetType
        art.url = defaultAsset.url
      }
    },
    assignArtToPosition(position, art) {
      if (!position || !art)
        return

      if (position.element) {
        position.element.parentNode.removeChild(position.element);
      }
      position.scene = this.$refs.scene
      position.src = (art.thumbnails ? art.thumbnails.x512 : null) || art.url || art.image
      position.artId = art.id
      position.imgHeight = (art.height512 || 512) / 512
      position.imgWidth = (art.width512 || 512) / 512
      position.frame = art.frame

      // assign the art to that frame
      art.frameId = position.id


      if (art.size)
        position.scale = art.size / 110

      position.heightOffset = art.heightOffset || 0

      position.question = false

      const elm = Rooms.addArt(position)
      position.element = elm
      elm.addEventListener('click', this.openArt.bind(this))
      elm.addEventListener('mouseenter', this.previewArt.bind(this))
    },
    removeArt(frameId){
      this.assignBlankToPosition(this.frameMap[frameId])
    },
    assignBlankToPosition(position) {
      if (!position)
        return

      if (position.element) {
        position.element.parentNode.removeChild(position.element);
      }
      position.scene = this.$refs.scene
      position.src = ""
      position.artId = ""
      position.frame = false
      position.scale = 1
      position.heightOffset = 0
      position.question = true
      position.imgHeight = 1
      position.imgWidth = 1

      const elm = Rooms.addArt(position)
      position.element = elm
      elm.addEventListener('click', this.openArt.bind(this))
      elm.addEventListener('mouseenter', this.previewArt.bind(this))
    },
    async openArt(el) {

      if (this.$isMobile()){
        this.previewArt(el)
        return
      }

      if (el && el.srcElement && el.srcElement.components && el.srcElement.components.artframe) {
        const artId = el.srcElement.components.artframe.data.artId;
        const frameId = el.srcElement.components.artframe.data.frameId;

        if (this.editorMode) {
          if (this.placeArtId && !artId) {
            let art = this.art.find(x => x.id == this.placeArtId)
            if (!art) {
              // add if it it's new!
              art = this.placeArt;
              this.art.push(art)
            }
            if (art.frameId) {
              // this art is already assigned
              this.assignBlankToPosition(this.frameMap[art.frameId])
            }
            this.assignArtToPosition(this.frameMap[frameId], art)
            parent.postMessage({ viewerAction: "artPlace", artId: art.id, frameId: frameId }, "*")
          } else {
            parent.postMessage({ viewerAction: "artClick", artId: artId, frameId: frameId }, "*")
          }

        } else {
          const art = this.art.find(x => x.id == artId)

          this.currentArt = art
          this.artDlg = true;
          if (art.proofGroup) {
            await this.getDefaultAsset(art)
          }
        }
      }
    },
    previewArt(el) {
      if (el && el.srcElement && el.srcElement.components && el.srcElement.components.artframe) {
        const artId = el.srcElement.components.artframe.data.artId;
        const art = this.art.find(x => x.id == artId)
        this.currentArt = art
        this.snackbar = true;
      }
    },
    async openSea() {
      console.log(this.currentArt)
      const url = `https://opensea.io/assets/${this.currentArt.chain}/${this.currentArt.nft.token_address}/${this.currentArt.nft.token_id}`
      window.open(url, "_blank")
    },
    fullScreen() {
      const elem = this.$refs.assetVideo || this.$refs.assetImage;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    },

  },

}
</script>


<style lang="scss">
a-scene {
  position: static
}

.instructionDlg {

  background: #FF7;
}

.galleryAsset {
  margin: 50px 100px;

  &__title {}

  &__image {
    padding: 10px;
  }

  &__description {
    padding: 10px;

    .text-h3 {
      margin: 20px 0px;
    }

    p {
      margin: 20px 0px;
      min-height: 100px;
      text-align: top;
      white-space: pre-wrap
    }
  }

  &__links {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    &_title {
      margin-top: 10px;
      border-bottom: 8px solid #DDD;
      font-size: 1em;
      padding: 5px 0px 0px 20px;
    }

    &_card {
      display: inline-block;

      .v-card__title {
        height: 50px;
        overflow: hidden;
      }

      .v-card__text {
        height: 40px;
        overflow: hidden;
      }
    }
  }

  &.popupDialog {
    margin: 0px;
  }
}

@media only screen and (max-width: 768px) {

  .galleryAsset {
    &__title {
      margin: 0px;
      overflow: hidden;
      text-overflow: clip;
    }

    margin: 0px 00px;

    &__image {
      padding: 20px 10px 0px 10px;
    }

    &__description {

      padding: 0px;

      .text-h3 {
        font-size: 2rem !important;
        margin: 0px 0px;
      }

      .text-h4 {
        font-size: 1.5rem !important;
        margin: 5px 0px;
      }

      .text-h6 {
        font-size: 1rem !important;
        margin: 5px 0px;
        min-height: 100px;
        text-align: top;
      }
    }

    &.popupDialog {
      margin: 0px;


    }
  }
}

.props-card {
  margin:4px;
  font-size:0.7em;
  float:left;
  width:120px;
  height:50px;
  .v-list-item--two-line { min-height:50px;}
}
</style>