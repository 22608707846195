import 'aframe-mouse-cursor-component'
require('@/plugins/physics/dist/aframe-physics-system');
window['CANNON'].Ray.prototype._updateDirection = window['CANNON'].Ray.prototype.updateDirection

const Gallery = {
  
  configureHall(options){

    console.log(options)
      
      },
    
}

export default Gallery