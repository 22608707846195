import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Gallery from "@/components/Gallery"
import Metasquare from "@/components/Metasquare"
//import Maze from "@/components/Maze"

// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
const routes = [
  { path: '/', component: Gallery },
  { path: '/metasquare/:collection/:proofGroup/:id', component: Metasquare, name: "metasquare" },
  { path: '/maze/:collection/:proofGroup/:id', component: Gallery, name: "maze" },
  { path: '/gallery/:collection/:proofGroup/:id', component: Gallery, name: "gallery" },
]


const router = new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
  })

export default router