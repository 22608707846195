import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import './plugins/mobile'


Vue.config.productionTip = false

Vue.config.ignoredElements = [
  'a-scene',
  'a-sphere',
  'a-asset-item',
  'a-image',
  'a-curvedimage',
  'a-assets',
  'a-text',
  'a-plane',
  'a-cylinder',
  'a-rounded',
  'a-light',
  'a-entity',
  'a-camera',
  'a-box',
  'a-sky',
  'a-cursor',
  'a-gltf-model',
  'a-triangle',
  'a-cubemap'
]

import PA from '@proveanything/base'
PA.setup({host:"https://beta.proveanything.com"})
//PA.setup({host:"http://127.0.0.1:8080"})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

