
const MetaSquares = {

    load(options){
      let grid = {}

      if (options.sport == "soccer"){
        grid = {
          xStep: 1.96,
          yStep: 2,
          xCount: 30,
          yCount: 46,
          sceneScale: "0.01 0.01 0.01"
        }
      } else if (options.sport == "rugby"){
        grid = {
          xStep: 2,
          yStep: 2,
          xCount: 50,
          yCount: 34,
          sceneScale: "1 1 1"
        }
      } else if (options.sport == "icehockey"){
        grid = {
          xStep: 1,
          yStep: 1,
          xCount: 56,
          yCount: 26,
          sceneScale: "0.01 0.01 0.01"
        }
      } else if (options.sport == "basketball"){
        grid = {
          xStep: 0.97,
          yStep: 0.97,
          xCount: 54,
          yCount: 26,
          sceneScale: "0.02 0.02 0.02"
        }
      }    

      console.log(options)

      options.sceneScale = grid.sceneScale

      const scene = options.scene
      const yStep = grid.yStep
      const yMin = (-grid.yCount * yStep)/2
      const yMax = (grid.yCount * yStep)/2 

      const xStep = grid.xStep
      const xMin = (-grid.xCount * xStep)/2
      const xMax = (grid.xCount * xStep)/2 


      console.log(yStep, yMin, yMax)
      console.log(xStep, xMin, xMax)
        
      this.setupFloor(options)

        for (let y = yMin; y <= (yMax+0.01); y+=yStep){
            const plane = document.createElement("a-entity");
            plane.setAttribute("line", `start: ${xMin} 0 ${y}; end: ${xMax} 0 ${y}; color:#0C0;`)
            scene.appendChild(plane)
          }
         for (let x = xMin; x <= (xMax+0.01); x+=xStep){
            const plane = document.createElement("a-entity");
            plane.setAttribute("line", `start: ${x} 0 ${yMin}; end: ${x} 0 ${yMax}; color:#0C0;`)
            scene.appendChild(plane)
          }
          let  squareId = 1
          let yPos = 1
          for (let y = (yMin+yStep/2); y <= (yMax-yStep/2) +0.01; y+=yStep){
            let xPos = 1
            for (let x = (xMin+xStep/2); x <= (xMax-xStep/2); x+=xStep){
              const plane = document.createElement("a-plane");
              plane.setAttribute("position", `${x} 0 ${y}`)
              plane.setAttribute("rotation", "-90 0 0")
              plane.setAttribute("width", xStep)
              plane.setAttribute("height", yStep)
              plane.setAttribute("opacity", 0.1)
              plane.setAttribute("color", "#0F0")
              plane.setAttribute("class", "square")                
              plane.addEventListener('click',options.openSquare)
              plane.addEventListener('mouseenter', options.highlightSquare)
              plane.setAttribute("squareId", squareId)
              plane.setAttribute("xPos", xPos)
              plane.setAttribute("yPos", yPos)

              scene.appendChild(plane)
              squareId+=1
              xPos +=1
            }
            yPos +=1
          }

    },

   setupFloor(options){

      if (options.sport == "basketball"){
        this.setupBasketball(options)
      } else {
        const gltf = document.createElement("a-entity");
        gltf.setAttribute("scale", options.sceneScale)
        gltf.setAttribute("position", "0 -0.1 0")
        gltf.setAttribute("gltf-model", `url(${options.baseUrl}squares/${options.sport}/scene.gltf)`)
        options.scene.appendChild(gltf)
    }
  },
  setupBasketball(options){

    options.assets.push({id: `courtfloor`, path: `squares/basketball/lakers.jpg`})
    options.assets.push({id: `floor`, path: `squares/basketball/floor.jpg`})

      // basketball needs 2 hoops
      let gltf = document.createElement("a-entity");
    gltf.setAttribute("scale", options.sceneScale)
    gltf.setAttribute("position", "28 -0.1 0")
    gltf.setAttribute("rotation", "0 90 0")
    gltf.setAttribute("gltf-model", `url(${options.baseUrl}squares/${options.sport}/scene.gltf)`)
    options.scene.appendChild(gltf)

    gltf = document.createElement("a-entity");
    gltf.setAttribute("position", "-28 -0.1 0")
    gltf.setAttribute("scale", options.sceneScale)
    gltf.setAttribute("rotation", "0 -90 0")
    gltf.setAttribute("gltf-model", `url(${options.baseUrl}squares/${options.sport}/scene.gltf)`)
    options.scene.appendChild(gltf)

    let court = document.createElement("a-plane");
    court.setAttribute("position", "0 -0.1 0")
    court.setAttribute("width", "30")
    court.setAttribute("height", "60")
    court.setAttribute("rotation", "-90 0 90")
    court.setAttribute("src", "#courtfloor")
    options.scene.appendChild(court)

    court = document.createElement("a-plane");
    court.setAttribute("position", "0 -0.2 0")
    court.setAttribute("width", "80")
    court.setAttribute("height", "50")
    court.setAttribute("rotation", "-90 0 00")
    court.setAttribute("src", "#floor")
    court.setAttribute("repeat", "24 15")
    options.scene.appendChild(court)


  }
}

export default MetaSquares