
const textures = {}

import maze1 from "@/assets/maze/1.htm"
import maze2 from "@/assets/maze/2.htm"

const mazes = {
  "1": maze1,
  "2": maze2
}

const Maze = { 

    configureTexture(options){
        textures[options.id] = options
    },
    applyTexture(entity, texture, repeatXY, lightmap = null){
      const options = textures[texture]
      
      let repeat = `${repeatXY.x} ${repeatXY.y}`
      if (options.repeat){
        repeat = `${repeatXY.x * options.repeat} ${repeatXY.y * options.repeat}`
      }
      const occRepeat = lightmap ? "1 1" : repeat

      const color = options.color || "#FFF"

      entity.setAttribute("src",  `#${texture}`)
      entity.setAttribute("normal-map", `#${texture}-normal`)
      entity.setAttribute("ambient-occlusion-map", `#${texture}-ambient`)
      entity.setAttribute("normal-texture-repeat", repeat)
      entity.setAttribute("ambient-occlusion-texture-repeat", occRepeat)
      entity.setAttribute("repeat", repeat)
      entity.setAttribute("color",  color)
      if (options.normalScale){
          entity.setAttribute("normal-scale", `${options.normalScale} ${options.normalScale}`)
      }
      if (options.rough){
        entity.setAttribute("roughness", 1)
        entity.setAttribute("roughness-map",  `#${texture}-rough`)
        entity.setAttribute("roughness-texture-repeat", repeat)
      } else {
        entity.setAttribute("roughness", 0.5)
      }

      if (options.height){
        entity.setAttribute("displacement-map",  `#${texture}-height`)
        entity.setAttribute("displacement-texture-repeat", repeat)
      } 

      if (options.emissive){
        entity.setAttribute("emissive",  options.emissive.color)
        entity.setAttribute("emissive-map",  `src: #${texture}-emissive`)
        entity.setAttribute("emissive-intensity",  options.emissive.intensity)
      }

      if (options.alpha){
        entity.setAttribute("alpha", 1)
        entity.setAttribute("alpha-map",  `src: #${texture}-alpha`)
      }
      if (options.metal){
         entity.setAttribute("metalness", 1)
         entity.setAttribute("metalness-map",  `#${texture}-metal`)
        entity.setAttribute("metalness-texture-repeat", repeat)
      } else {
        entity.setAttribute("metalness", 0)
      }
      if (lightmap){
        entity.setAttribute("light-map", `src: ${lightmap}` )

      }
    },

    load({scene, maze}){

      if (!window.AFRAME.components.skirting){
        window.AFRAME.registerComponent('skirting', {
            schema: {
            },
            init: function() {

              const width = this.el.components.geometry.data.width
              const height = this.el.components.geometry.data.height

              let geometry = new window.THREE.BoxGeometry(width+0.05, 0.25, 0.1)
              let material = new window.THREE.MeshStandardMaterial({
                color: "#DDD"
              })
              let mesh = new window.THREE.Mesh(geometry, material)
              mesh.position.set(0, (-height/2) +0.125 , 0.0 )

              this.el.object3D.add(mesh)                

            }
          });
      }

      if (!window.AFRAME.components.artframe){
        window.AFRAME.registerComponent('artframe', {
            schema: {
              frame: {
                default: false,
                type: 'boolean'
              },
              artId: {
                default: 0,
                type: 'number'
              }
            },
            init: function() {
              this.el.components.material.material.colorWrite = false

              const width = this.el.components.geometry.data.width
              const height = this.el.components.geometry.data.height

              const texture = new window.THREE.TextureLoader().load( this.el.attributes.src.value );
              let geometry = new window.THREE.PlaneGeometry(width, height)
              let material = new window.THREE.MeshBasicMaterial({
                map: texture
              })
              let mesh = new window.THREE.Mesh(geometry, material)
              mesh.position.set(0, 0, 0.14)

              this.el.object3D.add(mesh)

              let geometry1 = new window.THREE.PlaneGeometry(0.14, height)
              let material1 = new window.THREE.MeshBasicMaterial({
                color:'#444'
              })

              let mesh1 = new window.THREE.Mesh(geometry1, material1)
              mesh1.rotation.set(0, -Math.PI/2, 0)
              mesh1.position.set(-width/2, 0, 0.07)
              this.el.object3D.add(mesh1)

              let geometry2 = new window.THREE.PlaneGeometry(0.14, height)
              let mesh2 = new window.THREE.Mesh(geometry2, material1)
              mesh2.rotation.set(0, Math.PI/2, 0)
              mesh2.position.set(width/2, 0, 0.07)
              this.el.object3D.add(mesh2)

              let geometry3 = new window.THREE.PlaneGeometry(width, 0.14)
              let mesh3 = new window.THREE.Mesh(geometry3, material1)
              mesh3.rotation.set(-Math.PI/2, 0, 0)
              mesh3.position.set(0, height/2, 0.07)
              this.el.object3D.add(mesh3)

              let geometry4 = new window.THREE.PlaneGeometry(width, 0.14)
              let mesh4 = new window.THREE.Mesh(geometry4, material1)
              mesh4.rotation.set(Math.PI/2, 0, 0)
              mesh4.position.set(0, -height/2, 0.07)
              this.el.object3D.add(mesh4)

            

          }
        })
      }

      if (!window.AFRAME.components.occlude){
        window.AFRAME.registerComponent('occlude', {
            init: function() {
              var geometry = this.el.object3DMap.mesh.geometry;
              geometry.setAttribute( 'uv2', new window.THREE.BufferAttribute( geometry.attributes.uv.array, 2 ) );
          }
        })
      }

      if (!window.AFRAME.components['alpha-map']){
        window.AFRAME.registerComponent('alpha-map', {
          schema: {src: {type: 'map'}},
          init: function() {
            const data = {
              "alphaMap": this.data.src,
              "alphaTextureRepeat": this.el.components.material.data.repeat
            }
            window.AFRAME.utils.material.updateDistortionMap('alpha', this.el.components.material, data);
          }              
        });
      }
      if (!window.AFRAME.components['emissive-map']){
        window.AFRAME.registerComponent('emissive-map', {
          schema: {src: {type: 'map'}},
          init: function() {
            const data = {
              "emissiveMap": this.data.src,
              "emissiveTextureRepeat": this.el.components.material.data.repeat
            }
            window.AFRAME.utils.material.updateDistortionMap('emissive', this.el.components.material, data);
          }              
        });
      }  

        scene.appendChild(this.createFloor({x: 0, y: 0, width:200, depth:200, height: 0}))

        scene.appendChild(this.createCeiling({x: 0, y: 0, width:200, depth:200, height: 6}))

        const parser = new DOMParser();

        const xmlDoc = parser.parseFromString(mazes[maze] ,"text/html");

        const elms = Array.prototype.slice.call(xmlDoc.body.children[0].children[2].children)

        let art = []

        elms.forEach( (elm) => {
            if (elm.attributes.x1){

                art.push(this.calcWall({ scene,
                  x1: parseFloat(elm.attributes.x1.value),
                  y1: parseFloat(elm.attributes.y1.value),
                  x2: parseFloat(elm.attributes.x2.value),
                  y2: parseFloat(elm.attributes.y2.value),
                }))
            } else if (elm.attributes.points) {
              const pairs = elm.attributes.points.value.split(" ").map( pair => pair.split(","))
              for (let a = 0; a < pairs.length -1; a++){
                art.push(this.calcWall({ scene,
                  x1: parseFloat(pairs[a][0]),
                  y1: parseFloat(pairs[a][1]),
                  x2: parseFloat(pairs[a+1][0]),
                  y2: parseFloat(pairs[a+1][1]),
                }))
              }
            }
        })

        art = art.filter(n => n)
        return art 
    },
    calcWall(options){
      
      const scale= 4
      const x1 = options.x1/scale
      const y1 = options.y1/scale
      const x2 = options.x2/scale
      const y2 = options.y2/scale

      const mx = ((x1 + x2)/2 - 40)
      const my = ((y1 + y2)/2 - 40)
      
      const dx = x2 - x1;
      const dy = y2 - y1;
      const len = Math.sqrt((dx * dx) + (dy * dy))
      let angle = Math.atan2(dy, dx) * 180 / Math.PI

      options.scene.appendChild(this.createWall({
          x: mx, 
          y: my, 
          width:len, 
          depth:2, 
          rotation: `0 ${angle} 0`,
          height: 5
        }
      ))

      const art = Math.random()
      angle = Math.random() > 0.5 ? angle : 180+angle

      if (art > 0.5)
        return { x: mx, y: my , height: 5,  rotation: `0 ${angle} 0`, frame: false}
      else
        return null
    },

    createFloor(options){
        const repeat = {x: 10, y: 10}
        const plane = document.createElement("a-plane");
        plane.setAttribute("body", "type: static;shape: plane;")
        plane.setAttribute("position", `${options.x} ${options.z || 0 } ${-options.y}`)
        plane.setAttribute("rotation", "-90 0 0")
        plane.setAttribute("width", options.width)
        plane.setAttribute("height", options.depth)
        plane.setAttribute("occlude", "true")
        this.applyTexture(plane, options.texure || "floor1", repeat)

        return plane;
      },
      createCeiling(options){
        const repeat = {x: 4, y:4}
        const plane = document.createElement("a-plane");
        plane.setAttribute("position", `${options.x} ${(options.z || 0) + options.height } ${-options.y}`)
        plane.setAttribute("rotation", options.rotation || "90 0 0")
        plane.setAttribute("width", options.width)
        plane.setAttribute("material", "transparent: true;");
        plane.setAttribute("height", options.depth)
        plane.setAttribute("occlude", "true")
        this.applyTexture(plane, options.texure || "ceiling1", repeat)

        return plane;
      },      
      createWall(options){

    //    console.log("create wall", options)
    
        const repeat = {x: options.width/2, y:options.height/2}
        const plane = document.createElement("a-plane");
        plane.setAttribute("body", "type: static;shape: plane;")
        plane.setAttribute("position", `${options.x} ${(options.z || 0) +options.height/2} ${-options.y}`)
        plane.setAttribute("rotation", options.rotation)
        plane.setAttribute("width", options.width)
        plane.setAttribute("height", options.height)
        plane.setAttribute("occlude", "true")
        if (!options.noSkirting)
        plane.setAttribute("skirting", "true")
        if (!options.noMoulding)
        plane.setAttribute("moulding", "true")
        plane.setAttribute("material", "transparent: true;side: double");
        this.applyTexture(plane, options.texure || "wall1", repeat)
        if (options.wallLights && options.wallLights > 0){
        this.applyTexture(plane, options.texure || "wall1", repeat, `#wallLights${options.wallLights};`)
        } else {
        this.applyTexture(plane, options.texure || "wall1", repeat, false)

        }
        return plane;
      },         
}

export default Maze