<template>
  <v-app>
    <!--
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
      </div>

      <v-spacer></v-spacer>

    </v-app-bar>-->

    <v-main>
      <Gallery :microsite="microsite" />
    </v-main>
  </v-app>
</template>

<script>
import Gallery from './components/Gallery';

export default {
  name: 'App',

  components: {
    Gallery,
  },
  computed: {
    microsite() {
      let microsite = this.$ssrContext ? (this.$ssrContext.req.headers['x-forwarded-host'] || this.$ssrContext.req.headers.host) : location.host;
      if (this.$route.query.microsite)
        microsite = this.$route.query.microsite
        
      return microsite
    }
  },
  data: () => ({
    //
  }),
  mounted () {

    
  }
};
</script>
