<template>
  <div>
        <v-snackbar
          v-model="snackbar" v-if="currentArt"
        >
           {{currentArt.name}}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="artDlg = true"
            >
              Open
            </v-btn>
          </template>
        </v-snackbar>

        <v-dialog class="instructionDlg" v-if="!$vuetify.breakpoint.mobile" v-model="instructionDlg" max-width=800>
          <v-card>
           <v-card-title >
               Welcome
            </v-card-title>

            <v-card-text class="mt-3">
              <v-row>
                <v-col cols=8>
                  <p class="text-h5"> To navigate use mouse to look around and arrow keys or W,S,A,D to move</p>
                  <p class="text-h6"> Press escape to get your mouse pointer </P> 
                </v-col>
                <v-col cols=4>                  
                  <v-img contain src="controls.png"></v-img>     
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions >
                <v-spacer></v-spacer>
                <v-btn
                color="black"
                text
                @click="instructionDlg = false"
                >
                Close
                </v-btn>
            
            </v-card-actions>
          </v-card>
        </v-dialog>


        <div class="metaSquaresLogo" v-if="metaverse=='metasquares'">
          <v-img src="/squares/METASQUARES.png"></v-img>
        </div>

        <div class="squarePopup" v-if="squareDlg">
          <v-row>
            <v-col cols="6">
              <v-img src="/squares/turf.jpg"></v-img>
            </v-col>
            <v-col cols=6>
              <h1>SQUARE {{nftSquare.id}}</h1>
              <table>
                <tr>
                  <td> Position:</td>
                  <td> {{nftSquare.x}} x {{nftSquare.y}}</td>
                </tr>
                <tr>
                  <td> Tier:</td>
                  <td> Gold</td>
                </tr>
              </table>
              <v-btn class="green--text" x-large block tile depressed  color="white">AVailable</v-btn>

            </v-col>
            <v-col cols=12 class="squarePopup__details">
              <h4>Square Details</h4>
              <p>epulae torquent dui premo sem sit quia. cum curabitur auctor platea capto venio tum. autem obruo modo similis amet pagus. habitasse capto saepius ut hac quibus. facilisis tego volutpat nisl pneum. mara interdum lucidus abbas ex et non tamen.
          iaitu
              </p>
            </v-col>
            <v-col cols=12 class="squarePopup__subdetails">
              <p>
                <v-btn class="white--text float-left" @click="squareDlg = false" x-large tile color="grey darken-4">Close</v-btn>
                <v-btn class="white--text float-right" x-large tile color="grey darken-4">Buy Now</v-btn>
              </p>
            </v-col>
          </v-row>
        </div>



        <a-scene ref="scene" renderer="logarithmicDepthBuffer: true;" :stats="stats" light="defaultLightsEnabled: false" v-if="loaded && metaverse == 'metasquares'" cursor="rayOrigin: mouse" raycaster="objects: .square" loading-screen="dotsColor: red; backgroundColor: black">
            <a-assets >
              <img v-for="asset in assets" :key ="asset.id" :id="asset.id" :src="asset.path">          
            </a-assets>   

            <a-entity   id="rig" ref="camera" movement-controls="fly: true;speed:1" position="-30 5 0" rotation="0 -90 0">
              <a-entity id="camera"
                        camera="fov:70"
                         
                        position="0 1.9 0"        
                        look-controls="pointerLockEnabled: false">          
              </a-entity>
            </a-entity>

            <a-entity light="type: ambient; color: #AAA; intensity: 1"></a-entity>
            <a-entity light="type: directional; color: #FFF; intensity: 0.2" position="-0.5 1 1"></a-entity>

            <a-sky id="sky" color="#8DF"  ></a-sky>
          </a-scene>

  </div>
</template>

<script>

import MetaSquares from '@/components/aframe/metasquares'
import Vue from 'vue'
import PA from '@proveanything/base'

export default {
    name: 'Metasquare',
    props: {
    },
    data: () => ({
      currentArt: null,
      artDlg: false,
      snackbar: false,
      autoplay: true,
      assets: [],
      id: 0,
      gallerySetup: null,
      positions: [],
      loaded: false,
      stats: false,
      metaverse: "",
      currentSquare: null,
      squareDlg: false,
      nftSquare: {},
      instructionDlg: true
    }),
    watch: {
      '$route.params.id': function() {
        window.location.reload();
      },
      '$route.query.picture': function() {
        window.location.reload();
      },
      '$route.query.stats': function() {
        this.stats = this.$route.query.stats
      },
      'artDlg': function() {
        //reset velocity and keys
        this.$refs.camera.components['keyboard-controls'].localKeys = {}
      }
    },
    async mounted() {

      
     
      this.instructionDlg = false

      // support various routing versions
      this.id = this.$route.params.id
      
      this.stats = this.$route.query.stats
      this.loadSquareMetaverse();    
      
    },
    methods: { 
      async loadSquareMetaverse(){
        this.assets = [
        ]

        let sport = this.id

        sport = sport || "soccer"  

        let options = {}    
        
        this.loaded = true;

         Vue.nextTick( () => {

          options.scene = this.$refs.scene;
          options.sport = sport
          options.openSquare = this.openSquare.bind(this)
          options.highlightSquare = this.highlightSquare.bind(this)
          options.assets = this.assets

          MetaSquares.load(options)
          
        });


      },
     
      async getSite(site){
        return PA.Core.Collection.get(site)

      },
      async getAllBySite(site){

        return PA.Core.ProofGroup.getAll(site)

      },
     
      async getAllByNft(site, proofGroup, nftId){
        return  await PA.Core.Token.get(site, proofGroup, nftId)
/*
        const nftDoc = await firebase.firestore()
          .collection("chains").doc(chain)
          .collection("proofGroups").doc(proofGroup)
          .collection("nfts").doc(nftId)
          .get();
              
        return nftDoc.data()*/

      },    
   
      fullScreen(){
        const elem = this.$refs.assetVideo || this.$refs.assetImage;

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
          }      
        },
        highlightSquare(el){ 
          const material = el.srcElement.components.material;

          if (this.currentSquare){
           this.currentSquare.components.material.material.opacity = 0.1
          }
          material.material.opacity = 1
          this.currentSquare = el.srcElement
        },
        openSquare(el){ 
          this.highlightSquare(el)

          const squareId = parseInt( el.srcElement.attributes.squareId.value)

          this.nftSquare = {
            id: squareId,
            x:  parseInt( el.srcElement.attributes.xPos.value),
            y:  parseInt( el.srcElement.attributes.yPos.value)
          }

          this.squareDlg = true
          

        },
      },
           
  }
</script>


<style lang="scss">

a-scene {
  position: static
}

 .instructionDlg {

    background: #FF7 ;
 }

  .metaSquaresLogo {
     z-index: 100;
      position:fixed;
      left: 30px;
      top: 30px;
      width: 300px;
  }
  .squarePopup {
    text-align: left;
    padding:20px;
    z-index: 100;
    position:fixed;
    right: 30px;
    top: 30px;
    width: 500px;
    background: #9F98 ;
    h1 {
      color:#FFF;
      
    }
    table {
      font-size: 22px;
      text-transform: uppercase;
      color: #FFF;
      td {
        padding-right: 5px;
      }
    }
    &__details {
       h4 {
        text-transform: uppercase;
        color:#3A3;
        
      }
      background-color: #FFF;
    }
    &__details {
      background-color: #FFF;
    }
  }
</style>