//import Components from '@/components/aframe/components'


const overlayImages = (canvas, id, images) => {
  canvas.width=2048
  canvas.height=1024
  
  const ctx = canvas.getContext('2d');
  ctx.globalCompositeOperation = 'lighten';

	images.forEach(image => {
    ctx.globalAlpha = image.opacity ? image.opacity : 1;
    if (image.width)
      return ctx.drawImage(image.img, 0, 0, image.img.width, image.img.height, image.x || 0, image.y || 0, image.width, image.height);
    else
      return ctx.drawImage(image.img, image.x || 0, image.y || 0);
  });

  var img = new Image();
  img.src = canvas.toDataURL();
  img.id=id
  return img
}


exports.generate = (id, maps, assets)  => {

  const canvas = window.document.createElement('canvas');

  const result = overlayImages(canvas, id, maps)

  assets.appendChild(result)

  //Components.addLightmap(id, result)


}